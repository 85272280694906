import s from './RestaurantQuickFilter.module.sass'

import React, { FC, useMemo } from 'react'

import { useAppSelector } from '@app/model/store'
import { FilterItem } from '@widgets/Restaurant/RestaurantQuickFilter/ui/FilterItem'
import { Carousel } from '@shared/ui/Layout/Carousel'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

import IconArrow from '@icons/icon-arrow-quick-filter.svg'
import { RestaurantQuickFilterSkeleton } from '@widgets/Restaurant/RestaurantQuickFilter/index'

interface Props {
  className?: string
}

const RestaurantQuickFilter: FC<Props> = ({ className = '' }) => {
  const globalStore = useAppSelector((store) => store)
  const quickFilters = globalStore.pages.app_home.sections.quickFilters

  const quickFiltersMemo = useMemo(() => {
    return quickFilters.list?.map((filter) => (
      <FilterItem key={`${globalStore.global.currentCity.id}-${filter.slug}`} data={filter} />
    ))
  }, [quickFilters])

  if (quickFilters.loading || !globalStore.global.cityRead) {
    return <RestaurantQuickFilterSkeleton className={className} />
  }

  return (
    <ErrorBoundary>
      {!!quickFilters.list?.length && (
        <div className={`${s.wrapper} ${className}`}>
          <Carousel>
            <div className={s.inner}>
              <div className={s['slider-controls']}>
                <Carousel.Arrow direction={'prev'} className={s['slider-arrow--prev']}>
                  <div className={s['arrow-button']}>
                    <IconArrow />
                  </div>
                </Carousel.Arrow>

                <Carousel.Arrow direction={'next'} className={s['slider-arrow--next']}>
                  <div className={s['arrow-button']}>
                    <IconArrow />
                  </div>
                </Carousel.Arrow>
              </div>

              <Carousel.Slider shadow={true} slideMoveCount={3} className={s.slider}>
                {quickFiltersMemo}
              </Carousel.Slider>
            </div>
          </Carousel>
        </div>
      )}
    </ErrorBoundary>
  )
}

export default RestaurantQuickFilter
