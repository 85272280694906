import Link from 'next/link'
import Image from 'next/image'
import React, { FC, memo, useRef } from 'react'

import s from './PromoCard.module.sass'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

import PromoCardSummerTerracesDesktop from '@images/promo-card/promo-card-summer-terraces--desktop.png'
import PromoCardSummerTerracesMobile from '@images/promo-card/promo-card-summer-terraces--mobile.png'

// import PromoCard2004Desktop from '@images/promo-card/promo-card-2024--desktop.png'
// import PromoCard2004Mobile from '@images/promo-card/promo-card-2024--mobile.png'

import PromoCardGastroGidDesktop from '@images/promo-card/promo-card-gastrogid--desktop.png'
import PromoCardGastroGidMobile from '@images/promo-card/promo-card-gastrogid--mobile.png'

import PromoCardTgBotDesktop from '@images/promo-card/promo-card-tg-bot--desktop.png'
import PromoCardTgBotMobile from '@images/promo-card/promo-card-tg-bot--mobile.png'
import PromoCardOwnerDesktop from '@images/promo-card/promo-card-owner--desktop.png'
import PromoCardOwnerMobile from '@images/promo-card/promo-card-owner--mobile.png'

import { ErrorBoundary } from '../../6_shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'

interface Props {
  src: {
    desktop: string
    mobile: string
  }
  href: string
  title: string
  className?: string
  index?: any
}

const PromoCard: FC<Props> = ({ src, href, title, className = '', index }) => {
  const { isMobile } = useClientResponsive()
  const element = useRef()
  const getImageSrc = () => {
    const targetSrc = isMobile ? src.mobile : src.desktop

    let outputImage = null

    if (targetSrc === 'promo-card-summer-terraces--desktop') {
      outputImage = PromoCardSummerTerracesDesktop
    } else if (targetSrc === 'promo-card-summer-terraces--mobile') {
      outputImage = PromoCardSummerTerracesMobile
    }

    // if (targetSrc === 'promo-2024--desktop') {
    //   outputImage = PromoCard2004Desktop
    // } else if (targetSrc === 'promo-2024--mobile') {
    //   outputImage = PromoCard2004Mobile
    // }

    if (targetSrc === 'promo-card-gastrogid--desktop') {
      outputImage = PromoCardGastroGidDesktop
    } else if (targetSrc === 'promo-card-gastrogid--mobile') {
      outputImage = PromoCardGastroGidMobile
    }

    if (targetSrc === 'promo-card-tg-bot--desktop') {
      outputImage = PromoCardTgBotDesktop
    } else if (targetSrc === 'promo-card-tg-bot--mobile') {
      outputImage = PromoCardTgBotMobile
    }

    if (targetSrc === 'promo-card-owner--desktop') {
      outputImage = PromoCardOwnerDesktop
    } else if (targetSrc === 'promo-card-owner--mobile') {
      outputImage = PromoCardOwnerMobile
    }

    return outputImage
  }

  const handleLink = () => {
    EGG.entity.promo_card.promo_card_click({ section_index: index })
  }

  useIntersectionAnalytic(element, () => EGG.entity.promo_card.promo_card_view({ section_index: index }))

  return (
    <ErrorBoundary>
      <Link
        href={href}
        ref={element}
        className={`${s.card} ${className}`}
        target='_blank'
        onClick={handleLink}
        scroll={false}
        title={title}>
        {isMobile ? (
          <Image src={getImageSrc()} width={534 / 2} alt='rest-promo-card-item' />
        ) : (
          <Image src={getImageSrc()} width={804 / 2} alt='rest-promo-card-item' />
        )}
      </Link>
    </ErrorBoundary>
  )
}

export default memo(PromoCard)
