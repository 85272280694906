import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { checkFieldErrorByType } from '@shared/lib/fields/checkFieldErrorByType/checkFieldErrorByType'
import { FieldBase } from '@shared/types/fields/fields'

type TypeHandleValid = (showError?: boolean) => FieldBase

export function useField(field: FieldBase): [FieldBase, Dispatch<SetStateAction<FieldBase>>, TypeHandleValid] {
  const [state, setState] = useState(field)
  const [valueHasChanged, setValueHasChanged] = useState(false)

  useEffect(() => {
    !valueHasChanged && setValueHasChanged(field.value !== state.value)
  }, [state.value])

  const timeoutID = useRef()
  useEffect(() => {
    if (state.autoValid) {
      clearTimeout(timeoutID.current)
      // @ts-ignore
      timeoutID.current = setTimeout(() => {
        const showError = valueHasChanged || field.value !== state.value
        handleValid(showError)
      }, 800)
    }
  }, [state.value])

  const handleValid: TypeHandleValid = (showError = state.settings.showError) => {
    // Проверка на типы валидации в state.validations
    const getErrors = Object.keys(state.validations).map((type) => checkFieldErrorByType(type, state))

    const getFirstError = getErrors.find((item) => item !== false)

    setState((prev) => ({
      ...prev,
      error: showError ? getFirstError || '' : '',
      valid: !getFirstError,
    }))
    return {
      ...state,
      error: showError ? getFirstError || '' : '',
      valid: !getFirstError,
    }
  }

  return [state, setState, handleValid]
}
