import React, { FC, useEffect, useState } from 'react'
import { useField } from '@shared/lib/fields/hooks/useField/useField'
import { getDefaultField } from '@shared/lib/fields/getDefaultField'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { middlewareAuthorized } from '@shared/lib/helpers/auth'
import { changeComponentCreate } from '@entities/Restaurant/Reviews/model/reducer'
import { sendCreateReview } from '@entities/Restaurant/Reviews/model/createAsyncThunk'
import iconThumb from '@icons/icon-thumb.svg'
import { CreateReviewBlock } from '@entities/Restaurant/Reviews/ui/CreateReview/ui/CreateReviewBlock'
import { CreateReviewModal } from '@entities/Restaurant/Reviews/ui/CreateReview/ui/CreateReviewModal'
import { ErrorBoundary } from '../../../../../6_shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'

interface Props {
  mode: 'block' | 'modal'
}

const CreateReview: FC<Props> = ({ mode }) => {
  const stateReviews = useAppSelector((state) => state.entities.restaurant_reviews)
  const stateReviewCreate = stateReviews.components.create
  const dispatch = useAppDispatch()
  const [rateError, setRateError] = useState(false)

  const [comment, setComment, validComment] = useField(
    getDefaultField('textarea', {
      name: 'comment',
      required: true,
      validations: {
        'min-symbols-50': 'Отзыв должен содержать более 50 символов',
        'max-symbols-1500': 'Отзыв должен содержать не более 1500 символов',
      },
      settings: {
        placeholder: 'Ваш отзыв',
        height: '135px',
        resize: true,
        label: '',
      },
    }),
  )

  const handleClickDislike = () => {
    dispatch(changeComponentCreate({ is_like: stateReviewCreate.is_like === false ? null : false }))
    setRateError(false)
  }
  const handleClickLike = () => {
    dispatch(changeComponentCreate({ is_like: stateReviewCreate.is_like === true ? null : true }))
    setRateError(false)
  }

  useEffect(() => {
    // @ts-ignore
    dispatch(changeComponentCreate({ body: comment.value }))
    // @ts-ignore
  }, [comment.value])

  const validRate = () => {
    return stateReviewCreate.is_like !== null
  }

  const handleSubmit = () => {
    const isValidComment = validComment().error === ''
    const isValidRate = validRate()

    if (isValidComment && isValidRate) {
      EGG.entity.reviews.send_review_message_click()
      EGG.entity.reviews.send_rating_click({ rating_action: Number(isValidRate) })

      dispatch(sendCreateReview())
    } else {
      setRateError(!isValidRate)
      console.error('new review is invalid')
    }
  }

  const CreateReviewComponent = mode === 'modal' ? CreateReviewModal : CreateReviewBlock
  return (
    stateReviewCreate.visible && (
      <ErrorBoundary>
        <CreateReviewComponent
          rateError={rateError}
          handleClickDislike={handleClickDislike}
          handleClickLike={handleClickLike}
          handleSubmit={handleSubmit}
          // @ts-ignore
          comment={comment}
          // @ts-ignore
          setComment={setComment}
        />
      </ErrorBoundary>
    )
  )
}

export default CreateReview
