import React, { FC, ReactNode } from 'react'
import s from './ContainerTwoColumns.module.sass'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  children: ReactNode
  className?: string
}

type ComponentExtensions = {
  Main: typeof ContainerTwoColumnsMain
  Aside: typeof ContainerTwoColumnsAside
}

const ContainerTwoColumnsMain: FC<
  Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, className = '', ...props }) => {
  return (
    <main className={`${s.main} ${className}`} {...props}>
      {children}
    </main>
  )
}

const ContainerTwoColumnsAside: FC<
  Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, className = '', ...props }) => {
  return (
    <aside className={`${s.aside} ${className}`} {...props}>
      {children}
    </aside>
  )
}

const ContainerTwoColumns: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> &
  ComponentExtensions = ({ children, className = '', ...props }) => {
  return (
    <ErrorBoundary>
      <div className={`${s.container} ${className}`} {...props}>
        {children}
      </div>
    </ErrorBoundary>
  )
}

ContainerTwoColumns.Main = ContainerTwoColumnsMain
ContainerTwoColumns.Aside = ContainerTwoColumnsAside

export default ContainerTwoColumns
