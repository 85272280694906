import React, { FC } from 'react'
import s from './OwnerMobileWarning.module.sass'
import { Title } from '@shared/ui/Typography/Title'
import { Text } from '@shared/ui/Typography/Text'
import IconError from '@icons/large-icons/error.svg'
import { ContainerLongCenter } from '@shared/ui/Layout/Containers/ContainerLongCenter'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

const OwnerMobileWarning: FC = () => {
  return (
    <ErrorBoundary>
      <ContainerLongCenter classNameContent={s['container-content']}>
        <IconError />
        <div className={s.content}>
          <Title sizes={'h3'}>
            мобильная версия <br /> не доступна
          </Title>
          <Text sizes={'S'} className={s.text}>
            Для корректной работы личного кабинета ресторатора откройте его на планшете или компьютере
          </Text>
        </div>
      </ContainerLongCenter>
    </ErrorBoundary>
  )
}

export default OwnerMobileWarning
