import merge from 'lodash/merge'
import {
  FieldBase,
  FieldCheckbox,
  FieldInput,
  FieldInputTel,
  FieldRadio,
  FieldSelect,
  FieldTextarea,
} from '../../types/fields/fields'

const fieldBase: FieldBase = {
  type: 'field',
  name: 'field',
  value: '',
  required: false,
  disabled: false,
  visible: true,
  error: '',
  validations: {}, // required, email, phone, max-symbols-{numbers}, min-symbols-{numbers}
  autoValid: true,
  valid: false,
  settings: {
    label: 'Заголовок',
    showRequired: true,
    showError: true,
  },
}

const textarea: FieldTextarea = merge({}, fieldBase, {
  type: 'textarea',
  name: 'textarea',
  settings: {
    placeholder: 'Введите значение',
    hint: '',
    height: '160px',
    resize: false,
  },
})

const input: FieldInput = merge({}, fieldBase, {
  type: 'input',
  name: 'input',
  settings: {
    placeholder: 'Введите значение',
    hint: '',
    mask: null,
  },
})

const select: FieldSelect = merge({}, fieldBase, {
  type: 'select',
  name: 'select',
  value: null,
  settings: {
    hint: null,
    placeholder: 'Выберите категорию',
    defaultOption: null,
    options: [{ value: 'тест', label: 'тест', disabled: false }],
  },
})

const radio: FieldRadio = merge({}, fieldBase, {
  type: 'radio',
  name: 'radio',
  value: null,
  settings: {
    hint: 'Подсказка',
    options: [{ id: '1', name: '1', label: 'тест', disabled: false }],
  },
})

const checkbox: FieldCheckbox = merge({}, fieldBase, {
  type: 'checkbox',
  name: 'checkbox',
  value: [],
  settings: {
    hint: 'Подсказка',
    options: [{ id: '1', name: '1', label: 'тест', disabled: false }],
  },
})

const input_tel: FieldInputTel = merge({}, fieldBase, {
  type: 'input',
  name: 'input_tel',
  validations: {
    phone: 'Телефон введен неверно',
  },
  settings: {
    hint: '',
    placeholder: 'Введите значение',
    isErrorsVisible: true,

    mask: (value, field): string => {
      const x = value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)

      if (value === '') return ''
      if (!x[1]) return '+'
      if (!x[2]) return `+${x[1]}`

      return (
        `+${x[1]} (${x[2]}` +
        (x[3] ? `) ${x[3]}` : '') +
        (x[4] ? `-${x[4]}` : '') +
        (x[5] ? `-${x[5]}` : '') +
        (x[6] ? `-${x[6]}` : '')
      )
    },
  },
})

const input_email: FieldInputTel = merge({}, fieldBase, {
  type: 'input',
  name: 'input_email',
  validations: {
    email: 'Почта введена неверно',
  },
  settings: {
    hint: '',
    placeholder: 'Ваша почта',
    mask: (value, field): string => {
      return value.replace('/[^а-яё]/iu', '')
    },
  },
})

const search: FieldInput = merge({}, fieldBase, {
  type: 'input',
  name: 'input',
  settings: {
    placeholder: 'Введите значение',
    hint: '',
    mask: null,
  },
})

const DEFAULT_FIELDS: { [key: string]: object } = {
  textarea,
  input,
  select,
  checkbox,
  radio,
  input_tel,
  input_email,
  search,
}

export function getDefaultField(type: string, customFieldData: object = {}) {
  const modifiedPropsFieldData = (field) => {
    const result = { ...field }
    const isRequired = field.required
    const hasValueInValidations = result.validations && 'required' in result.validations

    if (isRequired && !hasValueInValidations) {
      result.validations.required = 'Обязательное поле'
    }

    return result
  }

  return modifiedPropsFieldData(merge({}, DEFAULT_FIELDS[type], customFieldData))
}
