import React, { FC, ReactNode } from 'react'
import s from './FontsProvider.module.sass'
import localFont from 'next/font/local'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  children: ReactNode
}

const FontAfishaDisplay = localFont({
  src: '../../../../public/fonts/AfishaDisplay/AfishaDisplay-Headline.woff',
  weight: '500',
  variable: '--font-a-headline',
  fallback: ['-apple-system', 'Helvetica', 'Arial', 'sans-serif'],
})

const FontSuisseIntlRegular = localFont({
  src: '../../../../public/fonts/SuisseIntl/SuisseIntl-Regular-WebXL.woff2',
  weight: '400',
  variable: '--font-regular',
  fallback: ['-apple-system', 'Helvetica', 'Arial', 'sans-serif'],
})

const FontSuisseIntlMedium = localFont({
  src: '../../../../public/fonts/SuisseIntl/SuisseIntl-Medium-WebXL.woff2',
  weight: '500',
  variable: '--font-medium',
  fallback: ['-apple-system', 'Helvetica', 'Arial', 'sans-serif'],
})

const FontsProvider: FC<Props> = ({ children }) => {
  return (
    <ErrorBoundary>
      <div
        className={`${FontSuisseIntlRegular.className} ${FontAfishaDisplay.variable} ${FontSuisseIntlRegular.variable} ${FontSuisseIntlMedium.variable}`}>
        {children}
      </div>
    </ErrorBoundary>
  )
}

export default FontsProvider
