import React, { FC } from 'react'
import s from './FilterOnceTemplate.module.sass'
import { TypeFiltersByKeyOnce } from '@features/Restaurant/RestaurantFilters/model/types'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { BtnFilterTag } from '@shared/ui/Form/Single/Filters/DefaultTag'
import { BtnFilterAfishaTag } from '@shared/ui/Form/Single/Filters/AfishaTag'
import { setCheckedFilterItemAsync } from '@features/Restaurant/RestaurantFilters/model/createAsyncThunk'
import { EGG } from '@shared/api/analytics'
import { getFilterSelected } from '@features/Restaurant/RestaurantFilters/helpers/helper'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  typeFilter: TypeFiltersByKeyOnce
  handleChange: () => void
}

const title = {
  afishaSelected: 'Выбор Афиши',
  hasBooking: 'Бронирование',
  openNow: 'Открыто сейчас',
}

const FilterOnceTemplate: FC<Props> = ({ typeFilter, handleChange }) => {
  const dispatch = useAppDispatch()
  const stateFilters = useAppSelector((store) => store.features.restaurant_filters)
  const { checked } = stateFilters.filters[typeFilter]

  const changeChecked = async () => {
    EGG.common.filter_use_click({
      filter_apply_type: typeFilter,
      filters_apply: getFilterSelected(),
      filter_apply_value: String(!checked),
    })
    await dispatch(
      setCheckedFilterItemAsync({
        filterType: typeFilter,
        checked: !checked,
      }),
    )
    handleChange()
  }

  const Tag = typeFilter === 'afishaSelected' ? BtnFilterAfishaTag : BtnFilterTag

  return (
    <ErrorBoundary>
      <Tag onClick={changeChecked} active={checked}>
        {title[typeFilter]}
      </Tag>
    </ErrorBoundary>
  )
}

export default FilterOnceTemplate
