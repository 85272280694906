import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import s from './RestaurantNews.module.sass'
import { NewsCard, NewsCardSkeleton } from '@widgets/News/NewsCard'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { globalStore, useAppDispatch, useAppSelector } from '@app/model/store'
import { KeenSliderOptions } from 'keen-slider/react'
import { fetchNewsAboutRestaurantList } from '@pages/RestItem/model/createAsyncThunk'

interface Props {
  restId: number | string
}

const RestaurantNews: FC<Props> = ({ restId }) => {
  const dispatch = useAppDispatch()
  const stateRestItem = useAppSelector((state) => state.pages.rest_item)
  const newsData = stateRestItem.newsAboutRestList
  const [skeletonAdditionalLoading, setSkeletonAdditionalLoading] = useState([])
  const [newsList, setNewsList] = useState(null)

  useEffect(() => {
    setNewsList(newsData.list)
  }, [newsData.list])

  useEffect(() => {
    if (newsData.loading.addition && newsData.pagination) {
      const amountExpectedNews = Math.min(
        newsData.pagination.page.size,
        newsData.pagination.total - newsData.list.length,
      )
      setSkeletonAdditionalLoading(Array.from(Array(amountExpectedNews).keys()))
    } else {
      setSkeletonAdditionalLoading([])
    }
  }, [newsData.loading.addition, newsData.pagination])

  useEffect(() => {
    dispatch(fetchNewsAboutRestaurantList())
  }, [restId])

  const isShowComponent = (newsData.list == null && newsData.loading.full) || !!newsData.list?.length

  const handleOnSlideChange = (data: KeenSliderOptions) => {
    const store = globalStore.getState()
    const newsState = store.pages.rest_item.newsAboutRestList

    // @ts-ignore
    const sliderDetails = data.track.details
    const progressIsNeedLoadMore = sliderDetails.maxIdx - sliderDetails.abs <= 1
    const hasNextPage = newsState.pagination?.page.next.number
    const isNeedLoadPage = progressIsNeedLoadMore && hasNextPage && !newsState.loading.addition

    if (isNeedLoadPage) {
      dispatch(fetchNewsAboutRestaurantList())
    }
  }

  const CarouselItemsCards = useMemo(() => {
    if (!newsList?.length) return

    const array = newsList.map((news, index) => (
      <NewsCard
        sizes={index % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'}
        key={index}
        data={news}
        analytic={{
          section_name: `Материалы о ресторане`,
          section_index: index,
        }}
      />
    ))

    array.push(
      ...skeletonAdditionalLoading.map((index) => (
        <NewsCardSkeleton
          sizes={index % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'}
          key={'skeleton-' + index}
        />
      )),
    )

    return array
  }, [newsList, skeletonAdditionalLoading.length])

  return (
    isShowComponent && (
      <ErrorBoundary>
        <div className={s['news-wrap']}>
          <section>
            <SectionCarousel
              countSlidesOnContainer={3}
              title={'Материалы о ресторане'}
              skeleton={newsData.loading.full}>
              <SectionCarousel.Items onSlideChanged={handleOnSlideChange}>{CarouselItemsCards}</SectionCarousel.Items>
              <SectionCarousel.Skeletons>
                {Array.from(Array(4).keys()).map((i, idx) => (
                  <NewsCardSkeleton key={idx} sizes={idx % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'} />
                ))}
              </SectionCarousel.Skeletons>
            </SectionCarousel>
          </section>
        </div>
      </ErrorBoundary>
    )
  )
}

export default RestaurantNews
