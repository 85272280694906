import s from './OwnerBookingSubpage.module.sass'

import React from 'react'
import Image from 'next/image'

import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'

import { ContainerLongCenter } from '@shared/ui/Layout/Containers/ContainerLongCenter'

import IconArrowLeft24px from '@icons/icon-arrow-left--24px.svg'
import { IconBookingIsNotEnabled } from '@pages/OwnerPersonalPage/ui/components/IconBookingIsNotEnabled'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

/**
 * @function BookingSubpage - Главная
 */
const BookingSubpage = (props) => {
  console.info('BookingSubpage props', props)

  const restName = props.data?.name || ''
  const restId = props.data?.id || null
  const currentPage = props.currentPage

  const ownBookingTableData = [
    [{ value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: null }],
    [{ value: '2' }, { value: '4' }, { value: null }, { value: '8' }, { value: '10' }],
    [{ value: 'hello' }, { value: '6' }, { value: '9' }, { value: '12' }, { value: '15' }],
  ]

  const [hasOwnerBookingAccess, setHasOwnerBookingAccess] = React.useState(true)
  const handleSwitchAccess = (ev) => {
    setHasOwnerBookingAccess(!hasOwnerBookingAccess)
  }

  const handleShowFullRestInfo = (ev) => {
    console.info('handleShowFullRestInfo', ev)
  }

  const handleCreateBookingMsg = (ev) => {
    console.info('handleCreateBookingMsg', ev)
  }

  const handleTableChangePage = (state, ev) => {
    if (state === 'prev') {
      console.info('handleCreateBookingMsg state prev, api req', ev)
    }

    if (state === 'next') {
      console.info('handleCreateBookingMsg state prev, api req', ev)
    }
  }

  // const isDev = window.location.search.indexOf('is_dev=true') > 0

  return (
    <ErrorBoundary>
      <ContainerLongCenter classNameContent={s['page-content-wrap']}>
        {/* {isDev && (
        <div className={s['page-dev-button']}>
          <Button sizes={'S'} mode={'black'} onClick={(ev) => handleSwitchAccess(ev)}>
            access
          </Button>
        </div>
      )} */}

        {hasOwnerBookingAccess ? (
          <>
            <section className={s['page-content-section']} data-rest-id={restId} data-current-page={currentPage}>
              <div className={s['page-content-two-cols-with-right-block']}>
                <div className={s['page-content-white-block']}>
                  <div className={s['own-book-rest-info']}>
                    {/*@ts-ignore*/}
                    <Image
                      className={s['own-book-rest-info-logo']}
                      width={'78'}
                      height={'78'}
                      alt={`Фото ресторана ${restName}`}
                    />

                    <div className={s['own-book-rest-info-text-wrap']}>
                      <Title sizes={'h2'} tag={'h2'} className={s['own-book-rest-info-name']}>
                        {restName}
                      </Title>

                      <Text sizes={'S'} className={s['own-book-rest-info-address']}>
                        Москва, Тверская, 3, гостиница The Carlton, 12 этаж
                      </Text>
                    </div>

                    <Button
                      className={s['own-book-rest-info-expand-button']}
                      sizes={'L'}
                      mode={'white-border'}
                      onClick={(ev) => handleShowFullRestInfo(ev)}>
                      icon-arrow-down
                    </Button>
                  </div>
                </div>

                <div className={s['page-content-white-block']}>
                  <span>455 624 ₽</span>
                </div>
              </div>

              <div className={s['page-content-white-block']}>
                <div className={s['own-book-client-info']}>
                  <div className={s['own-book-client-info-description-wrap']}>
                    <Title sizes={'h3'} tag={'h3'} className={s['own-book-client-info-title']}>
                      Информация для гостей
                    </Title>
                    <Text sizes={'S'} className={s['own-book-client-info-text']}>
                      Создайте сообщение с&nbsp;важной информацией для гостей (например о&nbsp;депозите или
                      фейсконтроле), которая отобразится на&nbsp;форме бронирования вашего ресторана
                    </Text>
                  </div>

                  <Button
                    className={s['own-book-client-info-create-button']}
                    sizes={'L'}
                    mode={'white-border'}
                    onClick={(ev) => handleCreateBookingMsg(ev)}>
                    Создать сообщение
                  </Button>
                </div>
              </div>
            </section>

            <section className={s['page-content-section']}>
              <Title sizes={'h2'} tag={'h2'} className={s['own-book-list-title']}>
                Бронирования
              </Title>

              <div className={s['own-book-list-filter']}>
                <div className={s['own-book-list-filter-elem']}>
                  <label className={s['own-book-list-filter-label']} htmlFor='own-book-filter-1'>
                    <Text sizes={'M'}>Статус брони</Text>
                  </label>
                  <select className={s['own-book-list-filter-control']} name='own-book-filter-1' id='own-book-filter-1'>
                    <option value='default'>Активные</option>
                  </select>
                </div>

                <div className={s['own-book-list-filter-elem']}>
                  <label className={s['own-book-list-filter-label']} htmlFor='own-book-filter-2'>
                    <Text sizes={'M'}>Месяц</Text>
                  </label>
                  <select className={s['own-book-list-filter-control']} name='own-book-filter-2' id='own-book-filter-2'>
                    <option value='default'>Апрель 2024</option>
                  </select>
                </div>

                <div className={s['own-book-list-filter-elem']}>
                  <label className={s['own-book-list-filter-label']} htmlFor='own-book-filter-3'>
                    <Text sizes={'M'}>Дата или период</Text>
                  </label>
                  <Button
                    sizes={'M'}
                    className={s['own-book-list-filter-control']}
                    mode={'white-border'}
                    name='own-book-filter-3'
                    id='own-book-filter-3'>
                    <Text sizes={'M'} hidden>
                      Апрель 2024
                    </Text>
                    <span className={s['own-book-list-filter-button-icon']}></span>
                  </Button>
                </div>
              </div>

              <div className={s['own-book-list-table-wrap']}>
                {/*@ts-ignore*/}
                <table className={s['own-book-list-table']} cellspacing='0' cellpadding='0'>
                  <tr className={s['own-book-list-table-header']}>
                    <th className={s['own-book-list-table-header-cell']}>
                      <Text sizes={'S'}>Гость</Text>
                    </th>
                    <th className={s['own-book-list-table-header-cell']}>
                      <Text sizes={'S'}>Места и пожелания</Text>
                    </th>
                    <th className={s['own-book-list-table-header-cell']}>
                      <Text sizes={'S'}>Дата</Text>
                    </th>
                    <th className={s['own-book-list-table-header-cell']}>
                      <Text sizes={'S'}>Статус</Text>
                    </th>
                    <th className={s['own-book-list-table-header-cell']}>
                      <Text sizes={'S'}>Сумма чека</Text>
                    </th>
                  </tr>

                  {ownBookingTableData.map((thisTableItem) => {
                    const tableArrValues = thisTableItem.map((thisTableCell) => thisTableCell.value)
                    return (
                      <tr className={s['own-book-list-table-item']}>
                        {tableArrValues.map((thisCellValue) => {
                          return (
                            <td className={s['own-book-list-table-item-cell']}>
                              <div className={s['own-book-list-table-item-cell-content']}>{thisCellValue || '-'}</div>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </table>

                <div className={s['own-book-list-table-footer']}>
                  <Button
                    className={s['own-book-list-table-footer-button']}
                    sizes={'L'}
                    mode={'white-border'}
                    onClick={(ev) => handleTableChangePage('prev', ev)}>
                    <IconArrowLeft24px className={s['own-book-list-table-footer-icon']} />
                    <Text sizes={'M'} className={s['own-book-list-table-footer-text']}>
                      Предыдущий месяц
                    </Text>
                  </Button>

                  <Button
                    className={s['own-book-list-table-footer-button']}
                    sizes={'L'}
                    mode={'white-border'}
                    onClick={(ev) => handleTableChangePage('next', ev)}>
                    <Text sizes={'M'} className={s['own-book-list-table-footer-text']}>
                      Следующий месяц
                    </Text>
                    <IconArrowLeft24px
                      className={`${s['own-book-list-table-footer-icon']} ${s['own-book-list-table-footer-icon--right']}`}
                    />
                  </Button>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <section className={`${s['page-content-section']} ${s['page-content-section--centered']}`}>
              <IconBookingIsNotEnabled />

              <div className={s['booking-is-not-enabled']}>
                <Title sizes={'h2'} tag={'h3'} className={s['booking-is-not-enabled-title']}>
                  Бронирование не&nbsp;подключено
                </Title>

                <Text sizes={'ML'} className={s['booking-is-not-enabled-description']}>
                  <span>
                    Чтобы начать принимать брони на&nbsp;столики на&nbsp;Афише Рестораны, свяжитесь с&nbsp;нами{' '}
                  </span>
                  <a className={s['booking-is-not-enabled-mail-link']} href={'mailto:restaurants@afisha.ru'}>
                    restaurants@afisha.ru
                  </a>
                </Text>
              </div>
            </section>
          </>
        )}
      </ContainerLongCenter>
    </ErrorBoundary>
  )
}

export default BookingSubpage
