import React, { FC, useContext, useMemo } from 'react'
import s from './Carousel.module.sass'
import SliderArrow from '@icons/icon-arrow-slider.svg'
import { CarouselContext } from '@shared/ui/Layout/Carousel/CarouselContext'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'

const slideTo = (slider, direction: 'prev' | 'next', moveCount) => {
  const sliderData = slider?.current
  const currentIndex = slider.current.track.details?.abs
  const maxIndex = slider.current.track.details?.maxIdx
  const minIndex = slider.current.track.details?.minIdx

  if (direction === 'prev') {
    const moveIndex = currentIndex - moveCount >= 0 ? currentIndex - moveCount : minIndex
    sliderData.moveToIdx(moveIndex, true, { duration: (currentIndex - moveIndex) * 100 + 500 })
  }
  if (direction === 'next') {
    const moveIndex = currentIndex + moveCount <= maxIndex ? currentIndex + moveCount : maxIndex
    sliderData.moveToIdx(moveIndex, true, { duration: (moveIndex - currentIndex) * 100 + 500 })
  }
}

const CarouselControls: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  ...props
}) => {
  const [context, setContext] = useContext(CarouselContext)
  const { isMobile } = useClientResponsive()
  const slidesInSlider = context.slides

  const getIsDisabled = (direction) => {
    return direction === 'next' ? !context.details?.nextIndex : !context.details?.prevIndex
  }

  const DefaultArrow = ({ ...thisProps }) => {
    return (
      <IconButton
        sizes='M M'
        mode={'white-border'}
        className={`${s.arrow} ${thisProps.className}`}
        icon={SliderArrow}
        onClick={thisProps.handleClick}
        disabled={thisProps.disabled}
      />
    )
  }

  const isShowArrow = useMemo(() => {
    const SliderData = context.instanceRef?.current
    let needToShowArrow = false
    if (SliderData && !isMobile) {
      needToShowArrow = context.details?.maxIndex !== 0
    }
    return needToShowArrow
  }, [slidesInSlider, context.instanceRef, context.countSlidesOnContainer, isMobile])

  const handleClickArrow = (direction) => {
    slideTo(context.instanceRef, direction, context.slideMoveCount)

    if (context.analytic) {
      const mapping = {
        prev: 'back',
        next: 'forward',
      }
      EGG.common.slider_arrow_button_click(context.analytic, mapping[direction])
    }
  }

  return (
    <>
      {isShowArrow && (
        <div className={s.arrows} {...props}>
          <DefaultArrow
            className={s['arrow-previous']}
            handleClick={() => handleClickArrow('prev')}
            disabled={getIsDisabled('prev')}
          />
          <DefaultArrow
            className={s['arrow-next']}
            handleClick={() => handleClickArrow('next')}
            disabled={getIsDisabled('next')}
          />
        </div>
      )}
    </>
  )
}

type Props = {
  direction: 'prev' | 'next'
  className?: string
}

export const CarouselArrow: FC<
  Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ children, direction, className = '', ...props }) => {
  const [context, setContext] = useContext(CarouselContext)
  const buttonIsDisabled = direction === 'next' ? !context.details.nextIndex : !context.details.prevIndex

  const handleClickArrow = (direction) => {
    slideTo(context.instanceRef, direction, context.slideMoveCount)

    if (context.analytic) {
      const mapping = {
        prev: 'back',
        next: 'forward',
      }
      EGG.common.slider_arrow_button_click(context.analytic, mapping[direction])
    }
  }

  return (
    <ErrorBoundary>
      <button
        onClick={() => handleClickArrow(direction)}
        type='button'
        disabled={buttonIsDisabled}
        className={`${s.arrow} ${className}`}
        {...props}>
        {children}
      </button>
    </ErrorBoundary>
  )
}

export default CarouselControls
