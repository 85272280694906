import s from './HeaderAdvert.module.sass'

import React, { FC, memo, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

import Script from 'next/script'
import { useAppSelector } from '@app/model/store'
import Link from 'next/link'
import { SAMOKAT_CITIES } from './samokatCities'

const SHOW_ANY_ADV = process.env.SHOW_ANY_ADV

// adv-header Billboard TopBanner
const HeaderAdvert: FC = () => {
  const router = useRouter()
  const store = useAppSelector((store) => store.global)
  const { designEnv, isMobile } = useClientResponsive()
  const [hasDefaultAdv, setHasDefaultAdv] = useState(false)

  const [isActuallySamokatBanner, setIsActuallySamokatBanner] = useState(false)
  const [isShowSamokatInCurrentCity, setIsShowSamokatInCurrentCity] = useState(false)

  useEffect(() => {
    if (!store.currentCity) return

    const endDateSamokatAdv = new Date(2025, 0, 1, 0, 0)
    const currentDate = new Date()

    if (currentDate.getTime() < endDateSamokatAdv.getTime()) {
      // setIsActuallySamokatBanner(true)
      // const isRightCity = SAMOKAT_CITIES.some((city) => city == store.currentCity.name)
      // setIsShowSamokatInCurrentCity(isRightCity)
    }
  }, [store.currentCity])

  // Лендинг ресторатора со своей шапкой
  const isOwnerPage =
    store.currentPage == 'owner_marketing' ||
    store.currentPage == 'owner_booking' ||
    router.pathname == '/[city_slug]/restaurants/restorator'
  const isServiceMasterPages = store.currentPage === 'not_found' || store.currentPage === 'service_err'

  const isShowHeaderAdvert = !isOwnerPage && !isServiceMasterPages

  // основная логика для ADFox баннеров
  // отображение плоскости для верхней рекламы билборда
  const isShowAdv = SHOW_ANY_ADV && isShowHeaderAdvert && designEnv !== 'default'

  return isShowAdv ? (
    <div
      className={s['adv-header']}
      data-has-adv={hasDefaultAdv || (isShowSamokatInCurrentCity && isActuallySamokatBanner)}
      style={isShowSamokatInCurrentCity ? { backgroundColor: '#FF335F' } : {}}>
      <div className={s['adv-wrapper']}>
        <div className={`${s['adv-container']} ${isMobile ? s['adv-container--mobile'] : ''}`}>
          {isActuallySamokatBanner && (
            <div
              id='default_hype_container'
              className={`HYPE_document ${s['samokat-banner']}`}
              data-is-show-for-city={isShowSamokatInCurrentCity}
              style={{
                margin: 'auto',
                position: 'relative',
                width: '100%',
                height: '90px',
                overflow: 'hidden',
              }}>
              <Link
                style={{ width: '100%', cursor: 'pointer', zIndex: 99, height: '90px', position: 'absolute' }}
                href='https://samokat.go.link/promocategory/44bb0705-b64d-48c7-bc61-456e31c01b09?showStories&showcaseType=MINIMARKET&adj_t=5n3ryif&adj_campaign=mms&adj_adgroup=2024&adj_redirect=https%3A%2F%2Fsamokat.ru%2Fpromo%2F44bb0705-b64d-48c7-bc61-456e31c01b09&adj_redirect_macos=https%3A%2F%2Fsamokat.ru%2Fpromo%2F44bb0705-b64d-48c7-bc61-456e31c01b09'
                target='_blank'
              />
              {isMobile ? (
                <Script
                  type='text/javascript'
                  src='https://www.afisha.ru/rests/samokat/mobile/default_hype_generated_script.js?10771'
                />
              ) : (
                <Script
                  type='text/javascript'
                  src='https://www.afisha.ru/rests/samokat/desktop/default_hype_generated_script.js?37853'
                />
              )}
            </div>
          )}

          {!(isActuallySamokatBanner && isShowSamokatInCurrentCity) && (
            <AdvertTemplate
              type={'Billboard TopBanner'}
              className={`${s['adv-place']} ${isMobile ? s['adv-place--mobile'] : ''}`}
              onEmptyAdv={() => setHasDefaultAdv(false)}
              onLoadedAdv={() => setHasDefaultAdv(true)}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className={s.plug}></div>
  )
}

export default memo(HeaderAdvert)
