import React, { FC, useState } from 'react'
import s from './ReviewContent.module.sass'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { showComponentDelete, showComponentEdit } from '@entities/Restaurant/Reviews/model/reducer'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Avatar } from '@shared/ui/Media/Avatar'
import { Popup } from '@shared/ui/Overlays/Popup'
import { PopupContent } from '@shared/ui/Overlays/Popup/ui/PopupContent'
import { PopupTrigger } from '@shared/ui/Overlays/Popup/ui/PopupTrigger'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { EGG } from '@shared/api/analytics'
import { Text } from '@shared/ui/Typography/Text'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { PopupMenuItem } from '@shared/ui/Actions/PopupMenuItem'
import { DiscloseText } from '@shared/ui/Actions/DiscloseText'
import { AnswerItem } from '@entities/Restaurant/Reviews/ui/ReviewItem/ui/AnswerItem'
import { Modal } from '@shared/ui/Overlays/Modal'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeMappingReviewOutput } from '@shared/api/middleware/mappingAPI/review/type'
import IconThumb from '@icons/icon-thumb.svg'
import IconDots from '@icons/icon-dots.svg'

interface ReviewContentProps {
  data: TypeMappingReviewOutput
  mode?: 'card' | 'row'
  isModal?: boolean
  setOpenReviewMobileModal?: (value: boolean) => void
  analytic?: PickAnalytic<'section_index'>
}

const ReviewContent: FC<ReviewContentProps> = ({
  data,
  mode = 'row',
  isModal = false,
  setOpenReviewMobileModal,
  analytic,
}) => {
  const globalState = useAppSelector((state) => state.global)
  const dispatch = useAppDispatch()
  const { isMobile } = useClientResponsive()
  const [isOpenSettingsMenu, setIsOpenSettingsMenu] = useState(false)
  const [settingsButton, setSettingsButton] = useState()

  const isCurrentUserReview = globalState.user.data?.afisha_id === data.author.afishaId

  const handleChangeOpenSettings = () => setIsOpenSettingsMenu(true)
  const handleChangeCloseSettings = () => setIsOpenSettingsMenu(false)
  const handleOpenModalDelete = () => {
    dispatch(showComponentDelete(data.id))
    handleChangeCloseSettings()
  }
  const handleOpenModalEdit = () => {
    dispatch(
      showComponentEdit({
        id: data.id,
        body: data.body,
        is_like: data.like,
      }),
    )
    handleChangeCloseSettings()
  }

  const openFullReviewMobile = () => {
    if (isMobile && setOpenReviewMobileModal) {
      setOpenReviewMobileModal(true)
      handleOpenFullReviewAnalytic()
    }
  }

  const handleOpenFullReviewAnalytic = () => {
    EGG.entity.reviews.review_disclose_click({ section_index: analytic.section_index })
  }

  return (
    <ErrorBoundary>
      <a href={'https://www.afisha.ru/personalpage/' + data.author.afishaId} className={s['photo-wrap']}>
        <Avatar src={data.author.avatar} sizes={'XL L'} userName={data.author.name} />
      </a>

      <div className={s['content-head']}>
        <div className={s['head-main']}>
          <span className={`${s['like-type']} ${s[`like-type--${data.like ? 'positive' : 'negative'}`]}`}>
            <IconThumb />
          </span>

          <a href={'https://www.afisha.ru/personalpage/' + data.author.afishaId} className={s['head-info-wrap']}>
            <Text sizes={'M ML'} medium className={s['user-name']}>
              {data.author.name}
            </Text>
          </a>
        </div>

        {!isMobile && (
          <div className={s['date-action-wrapper']}>
            <Text tag={'span'} sizes={'S'} className={s.date}>
              {data.date}
            </Text>

            {isCurrentUserReview && (
              <div onClick={(e) => e.stopPropagation()}>
                <Popup
                  open={isOpenSettingsMenu}
                  onOpenChange={(value) => setIsOpenSettingsMenu(value)}
                  offset={0}
                  placement={'bottom-end'}
                  flip={false}>
                  <PopupTrigger>
                    <IconButton
                      icon={IconDots}
                      sizes={'S'}
                      mode={'white'}
                      onClick={() => setIsOpenSettingsMenu(!isOpenSettingsMenu)}
                    />
                  </PopupTrigger>
                  <PopupContent className={s.settings} style={{ width: '240px' }}>
                    <PopupMenuItem onClick={handleOpenModalEdit}>Редактировать</PopupMenuItem>
                    <PopupMenuItem onClick={handleOpenModalDelete}>Удалить</PopupMenuItem>
                  </PopupContent>
                </Popup>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={s['content']} onClick={openFullReviewMobile}>
        {isModal ? (
          <Text sizes={'M'} paragraph>
            {data.body}
          </Text>
        ) : (
          <DiscloseText
            maxHeight={184}
            className={s['disclose-comment']}
            colorShadow={mode === 'row' ? '#FFFFFF' : isMobile ? '#F2F2F2' : null}
            staticShadow={isMobile}
            handleClick={handleOpenFullReviewAnalytic}>
            <Text sizes={'M'} paragraph>
              {data.body}
            </Text>
          </DiscloseText>
        )}

        {isMobile && (
          <div className={s['date-action-wrapper']}>
            <Text tag={'span'} sizes={'S'} className={s.date}>
              {data.date}
            </Text>

            {isCurrentUserReview && (
              <div onClick={(e) => e.stopPropagation()}>
                <IconButton
                  // @ts-ignore
                  ref={setSettingsButton}
                  icon={IconDots}
                  sizes={'S'}
                  mode={'white'}
                  onClick={handleChangeOpenSettings}
                  className={s['settings-button']}
                />

                {isMobile && isOpenSettingsMenu ? (
                  <Modal
                    size={'S'}
                    buttonClose={false}
                    handleClose={() => setIsOpenSettingsMenu(false)}
                    className={s['settings-modal']}>
                    <div className={s['settings-modal-content']}>
                      <PopupMenuItem onClick={handleOpenModalEdit}>Редактировать</PopupMenuItem>
                      <PopupMenuItem onClick={handleOpenModalDelete}>Удалить</PopupMenuItem>
                      <PopupMenuItem onClick={() => setIsOpenSettingsMenu(false)} className={s['setting-cancel']}>
                        Отмена
                      </PopupMenuItem>
                    </div>
                  </Modal>
                ) : (
                  <Popup
                    triggerRef={settingsButton}
                    open={isOpenSettingsMenu}
                    onOpenChange={(value) => setIsOpenSettingsMenu(value)}
                    offset={0}
                    placement={'bottom-end'}
                    flip={false}>
                    <PopupContent className={s.settings} style={{ width: '240px' }}>
                      <PopupMenuItem onClick={handleOpenModalEdit}>Редактировать</PopupMenuItem>
                      <PopupMenuItem onClick={handleOpenModalDelete}>Удалить</PopupMenuItem>
                    </PopupContent>
                  </Popup>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {(!isMobile || mode === 'row') && <AnswerItem data={data.answer} className={s.answer} />}
    </ErrorBoundary>
  )
}

export default ReviewContent
