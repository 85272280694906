import React, { FC, useState } from 'react'
import s from './Gallery.module.sass'
import Image from 'next/image'
import { useKeenSlider } from 'keen-slider/react'

import IconPlay from '@icons/icon-play.svg'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import GalleryModal from '@shared/ui/Layout/Gallery/GalleryModal'
import { Text } from '@shared/ui/Typography/Text'
import { GallerySkeleton } from '@shared/ui/Layout/Gallery/index'

interface Props {
  list: {
    afisha_id: number
    author_object: {
      afisha_id: number
      first_name: string
      last_name: string
    }
    copyright: string
    cover_images: {
      '100x75': string
      '200x150': string
      '400x300': string
    }
    created: string
    description: string
    galleryType: string
    url: string
    priority: number
    height: number
    width: number
  }[]
  className?: string
  skeleton?: boolean
}

const GalleryWrapper: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  list,
  className = '',
  skeleton = false,
  ...props
}) => {
  if (skeleton) return <GallerySkeleton className={className} />
  else return <Gallery list={list} className={className} {...props} />
}

const Gallery: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  list,
  className = '',
  ...props
}) => {
  const [isOpenGalleryModal, setIsOpenGalleryModal] = useState(false)
  const [selectedSlide, setSelectedSlide] = useState(0)
  const { isDesktopLarge, isMobile } = useClientResponsive()

  const handleClickSlide = (index) => {
    setSelectedSlide(index)
    setIsOpenGalleryModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenGalleryModal(false)
  }

  const [sliderRef] = useKeenSlider({
    disabled: !isMobile,
    slides: {
      perView: 2.3,
      spacing: 8,
    },
    breakpoints: {
      '(min-width: 500px)': {
        slides: {
          perView: 3.3,
          spacing: 8,
        },
      },
      '(min-width: 1280px)': {
        slides: {
          perView: 4,
          spacing: 8,
        },
      },
      '(min-width: 1592px)': {
        slides: {
          perView: 5,
          spacing: 16,
        },
      },
    },
  })

  return (
    <ErrorBoundary>
      <div className={s.wrapper} {...props}>
        <div ref={sliderRef} key={list.length} className={`${s.container} ${className} keen-slider`}>
          {/*// @ts-ignore*/}
          {list.map((item, index) => {
            // @ts-ignore*/
            const isShowMore = list.length > (isDesktopLarge ? 5 : 4) && index === (isDesktopLarge ? 4 : 3)
            const isVideo = item.galleryType === 'video'

            return (
              index <= (isDesktopLarge ? 4 : 3) && (
                <div
                  className={`${s['media-item']} keen-slider__slide`}
                  data-video={item.galleryType === 'video'}
                  data-show-more={isShowMore}
                  key={item.afisha_id}
                  onClick={() => handleClickSlide(index)}>
                  <>
                    {isShowMore && (
                      <div className={s['media-item-action-container']}>
                        <Text sizes='XXL XL L' tag={'span'} className={s['media-item-more-number']}>
                          +{isDesktopLarge ? list.length - 5 : list.length - 4}
                        </Text>
                      </div>
                    )}

                    {isVideo && (
                      <>
                        {!isShowMore && (
                          <div className={s['media-item-action-container']}>
                            <button className={s['media-item-play-button']}>
                              <IconPlay />
                            </button>
                          </div>
                        )}
                        <video>
                          <source src={item.url} type='video/mp4' />
                        </video>
                      </>
                    )}

                    {!isVideo && (
                      <div
                        className='photo-wrapper'
                        key={item.afisha_id}
                        data-author={`${item.author_object.first_name} ${item.author_object.last_name}`}>
                        <Image
                          src={item.cover_images['400x300'] || ''}
                          alt='rest-gallery-item'
                          width={0}
                          height={153}
                          sizes='100vw'
                          style={{ width: '100%' }}
                        />
                      </div>
                    )}
                  </>
                </div>
              )
            )
          })}
        </div>

        {isOpenGalleryModal && (
          <GalleryModal selectSlide={selectedSlide} list={list} handleClose={handleCloseModal}></GalleryModal>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default GalleryWrapper
