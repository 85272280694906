import React, { FC } from 'react'
import s from './DeleteReview.module.sass'
import { Button } from '@shared/ui/Actions/Button'
import { Modal } from '@shared/ui/Overlays/Modal'
import { Title } from '@shared/ui/Typography/Title'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { hideComponentDelete } from '@entities/Restaurant/Reviews/model/reducer'
import { sendDeleteReview } from '@entities/Restaurant/Reviews/model/createAsyncThunk'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

const DeleteReview: FC = () => {
  const deleteData = useAppSelector((state) => state.entities.restaurant_reviews.components.delete)
  const dispatch = useAppDispatch()
  const { isMobile } = useClientResponsive()

  const handleClose = () => dispatch(hideComponentDelete())
  const handleDelete = () => dispatch(sendDeleteReview())

  return deleteData.visible ? (
    <ErrorBoundary>
      <Modal
        size={'S'}
        buttonClose={false}
        handleClose={handleClose}
        title={isMobile && 'Отзыв на ресторан удалится с оценкой безвозвратно. Удалить отзыв и оценку?'}
        className={s.modal}>
        <div className={s.wrapper} data-loading={deleteData.loading}>
          {!isMobile && (
            <Title tag={'h5'} sizes={'h3'} className={s.title}>
              Отзыв на ресторан удалится с оценкой безвозвратно. Удалить отзыв и оценку?
            </Title>
          )}

          <div className={s.buttons}>
            <Button sizes={'XL L'} mode={'white-border'} onClick={handleDelete} className={s.button}>
              Удалить отзыв
            </Button>

            <Button sizes={'XL L'} mode={'black'} onClick={handleClose} className={s.button}>
              Отменить
            </Button>
          </div>
        </div>
        <LoadingSpinner loading={deleteData.loading} />
      </Modal>
    </ErrorBoundary>
  ) : null
}

export default DeleteReview
