import s from './OwnerPostsPage.module.sass'

import Image from 'next/image'
import React, { useState, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import {
  openCreateForm,
  openEditMenuForm,
  openEditNewsForm,
  openEditSpecialForm,
} from '@pages/OwnerPersonalPage/model/OwnerPostsModel/reducer'

import { initPostsPage } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/createActions'

import { OwnerGuideBlock } from '@pages/OwnerPersonalPage/ui/components/OwnerGuideBlock'
import { OwnerPublishedOffer } from '@pages/OwnerPersonalPage/ui/components/OwnerPublishedOffer'

import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'

import IconEdit from '@icons/icon-edit.svg'
import IconRemove from '@icons/icon-remove.svg'
import IconArrowDown32 from '@icons/icon-select-arrow-down-32.svg'
import { postDelete } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/createAsyncThunk'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

/**
 * @function getLocalDate
 * @param thisDateJson - 2024-08-05T04:31:34.701Z
 * @returns 05.08.2024
 */
const getLocalDate = (thisDateJson) =>
  new Date(thisDateJson).toLocaleString('ru', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    // @ts-ignore
    timezone: 'UTC',
  })

/**
 * @function OwnerTabMegaphone - Объявления
 */
const OwnerPostsPage = (props) => {
  const dispatch = useAppDispatch()
  const thisState = useAppSelector((store) => store.pages.owner_personal.posts)

  const publishedData = thisState?.published || null

  // открытие формы в режиме редактирования
  // const handleEditMenu = (ev) => {
  //   dispatch(
  //     openEditMenuForm({
  //       dishes: menuItems.map((dish) => ({
  //         id: dish.id,
  //         title: dish.name,
  //         price: dish.price,
  //         description: dish.description,
  //         preview: dish.photoSrc,
  //       })),
  //       publicDate: null,
  //       modifiedDate: new Date().toJSON(),
  //     }),
  //   )
  // }

  const handleEditHistoryItem = (savedItem, ev) => {
    switch (savedItem.type) {
      case 'special':
        dispatch(
          openEditSpecialForm({
            id: savedItem.id,
            title: savedItem.title,
            description: savedItem.description,
            preview: savedItem.preview,
            modifiedDate: savedItem.modifiedDate,
          }),
        )
        break

      case 'news':
        dispatch(
          openEditNewsForm({
            id: savedItem.id,
            title: savedItem.title,
            description: savedItem.description,
            preview: savedItem.preview,
            publicDate: savedItem.publicDate,
          }),
        )
        break

      case 'menu':
        dispatch(
          openEditMenuForm({
            id: savedItem.id,
            modifiedDate: savedItem.modifiedDate,
            dishes: savedItem.dishes.map((dish) => ({
              id: dish.id,
              title: dish.title,
              price: dish.price,
              description: dish.description,
              preview: dish.preview,
            })),
          }),
        )
        break
    }
  }

  // удаление объявлений

  const handleRemoveHistoryItem = (storyItem, ev) => {
    const questionText = 'Вы уверены, что хотите удалить это объявление?'
    if (confirm(questionText)) {
      dispatch(
        postDelete({
          postId: storyItem.id,
          postType: storyItem.type,
        }),
      )
    }
  }

  // контент
  useEffect(() => {
    dispatch(initPostsPage({ id: props.data.id, name: props.data.name }))
  }, [])

  const isShowMore = false
  const [isHistoryVisible, setIsHistoryVisible] = useState(false)
  const handleToggleHistory = (ev) => {
    setIsHistoryVisible(!isHistoryVisible)
  }

  const handleClickOpenCreatePost = () => {
    dispatch(openCreateForm())
  }

  return (
    <ErrorBoundary>
      <div className={s['news-editor-content']}>
        <section
          className={`${s['news-editor-page-content-section']} ${s['news-editor-page-content-section--margin-bottom']}`}>
          <div className={s['page-content-white-block']}>
            <div className={s['news-editor']}>
              <Text className={s['news-editor-title']} sizes={'ML'}>
                Новое объявление
              </Text>

              <Text className={s['news-editor-description']} sizes={'S'}>
                Создайте спецпредложение, новость или объявление об&nbsp;обновлении меню
              </Text>

              <Button
                className={s['news-editor-button']}
                sizes={'L'}
                mode={'black'}
                onClick={handleClickOpenCreatePost}>
                Создать
              </Button>
            </div>
          </div>
        </section>

        {!!thisState.published && (
          <section className={`${s['news-editor-page-content-section']}`}>
            <OwnerPublishedOffer />
          </section>
        )}

        {/*{menuItems?.length > 0 && (*/}
        {/*  <section className={`${s['news-editor-page-content-section']}`}>*/}
        {/*    <div className={s['page-content-white-block']}>*/}
        {/*      <div className={s['news-editor-menu-wrap']}>*/}
        {/*        <Title className={s['news-editor-menu-title']} sizes={'h3'} tag={'h3'}>*/}
        {/*          Новые блюда*/}
        {/*        </Title>*/}

        {/*        <ul className={s['news-editor-menu-list']}>*/}
        {/*          {menuItems.map((thisMenu, i) => {*/}
        {/*            return (*/}
        {/*              <li className={s['news-editor-menu-list-item']} key={i}>*/}
        {/*                <div className={s['news-editor-menu-photo-wrap']}>*/}
        {/*                  <Image*/}
        {/*                    className={s['news-editor-menu-photo']}*/}
        {/*                    src={thisMenu.photoSrc}*/}
        {/*                    alt={thisMenu.photoAlt}*/}
        {/*                    layout='fill'*/}
        {/*                  />*/}
        {/*                </div>*/}

        {/*                <Text className={s['news-editor-menu-name']} sizes={'L'}>*/}
        {/*                  {thisMenu.name}*/}
        {/*                </Text>*/}

        {/*                <Text className={s['news-editor-menu-price']} sizes={'S'}>*/}
        {/*                  {thisMenu.price}*/}
        {/*                </Text>*/}

        {/*                <Text className={s['news-editor-menu-description']} sizes={'S'}>*/}
        {/*                  {thisMenu.description}*/}
        {/*                </Text>*/}
        {/*              </li>*/}
        {/*            )*/}
        {/*          })}*/}
        {/*        </ul>*/}

        {/*        <Button*/}
        {/*          className={s['news-editor-edit-button']}*/}
        {/*          sizes={'L'}*/}
        {/*          mode={'white-border'}*/}
        {/*          onClick={(ev) => handleEditMenu(ev)}>*/}
        {/*          <span className={s['news-editor-icon-edit']}>*/}
        {/*            <IconEdit />*/}
        {/*          </span>*/}
        {/*        </Button>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </section>*/}
        {/*)}*/}

        {thisState.previous?.length > 0 && (
          <section className={s['news-editor-page-content-section']}>
            <div className={`${s['news-editor-history']} ${isHistoryVisible ? s['news-editor-history--is-open'] : ''}`}>
              <button
                className={s['news-editor-history-toggle-button']}
                type='button'
                onClick={(ev) => handleToggleHistory(ev)}>
                <Text className={s['news-editor-history-title']} sizes={'ML'}>
                  Предыдущие объявления
                </Text>

                <div
                  className={`${s['news-editor-history-toggle-icon']} ${
                    isHistoryVisible ? s['news-editor-history-toggle-icon--is-open'] : ''
                  }`}>
                  <IconArrowDown32 />
                </div>
              </button>

              {isHistoryVisible && (
                <div className={s['news-editor-history-list-wrap']}>
                  <ul className={s['news-editor-history-list']}>
                    {thisState.previous.map((storyItem, i) => {
                      const data = {
                        id: 0,
                        type: null,
                        title: null,
                        text: null,
                        preview: null,
                        publishedDate: null,
                        saveDate: null,
                      }

                      if (storyItem.type === 'news' || storyItem.type === 'special') {
                        data.type = storyItem.type === 'special' ? 'Специальное предложение' : 'Новость'
                        data.id = storyItem.id
                        data.title = storyItem.title
                        data.text = storyItem.description
                        data.preview = storyItem.preview
                        data.publishedDate = storyItem.publicDate
                        data.saveDate = storyItem.modifiedDate
                      } else if (storyItem.type === 'menu') {
                        data.type = 'Новые блюда'
                        data.id = storyItem.id
                        data.title = storyItem.dishes.map((dish) => dish.title).join(', ')
                        data.text = storyItem.dishes.map((dish) => dish.description).join(', ')
                        data.preview = storyItem.dishes[0].preview || storyItem.dishes[0].preview
                        data.publishedDate = storyItem.publicDate
                        data.saveDate = storyItem.modifiedDate || storyItem.modifiedDate
                      } else return

                      return (
                        <li className={s['news-editor-history-item']} key={i}>
                          <div className={s['news-editor-history-item-photo-wrap']}>
                            {data?.preview && (
                              <Image
                                className={s['news-editor-history-item-photo']}
                                src={data.preview}
                                alt={'Фото'}
                                layout='fill'
                              />
                            )}
                          </div>

                          <span className={s['news-editor-history-item-description']}>
                            <Text sizes={'S'}>{data.type}</Text>
                            {data.saveDate && <span className={s['news-editor-history-item-bullit']}></span>}
                            {data.saveDate && <Text sizes={'S'}>Публикация {getLocalDate(data.saveDate)}</Text>}
                          </span>

                          <Text className={s['news-editor-history-item-text']} sizes={'M'}>
                            {data.title}
                          </Text>

                          <div className={s['news-editor-history-actions-wrap']}>
                            <Button
                              className={s['news-editor-history-action-remove']}
                              sizes='L'
                              mode='white-border'
                              onClick={(ev) => handleRemoveHistoryItem(storyItem, ev)}>
                              <span className={s['news-editor-icon-remove']}>
                                <IconRemove />
                              </span>
                            </Button>

                            <Button
                              className={s['news-editor-history-action-edit']}
                              sizes='L'
                              mode='white-border'
                              onClick={(ev) => handleEditHistoryItem(storyItem, ev)}>
                              <span className={s['news-editor-icon-edit']}>
                                <IconEdit />
                              </span>
                            </Button>
                          </div>
                        </li>
                      )
                    })}
                  </ul>

                  {isShowMore && (
                    <Button
                      className={s['news-editor-history-show-more']}
                      sizes={'L'}
                      mode='white-border'
                      type='button'>
                      Показать еще
                    </Button>
                  )}
                </div>
              )}
            </div>
          </section>
        )}

        <section className={s['news-editor-page-content-section']}>
          <OwnerGuideBlock />
        </section>
      </div>
    </ErrorBoundary>
  )
}

export default OwnerPostsPage
