import React, { ReactNode, FC } from 'react'

import s from './SectionCarousel.module.sass'

import { Carousel } from '@shared/ui/Layout/Carousel'
import { SectionTitleSkeleton } from '@shared/ui/Layout/SectionTitle'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

interface Props {
  children?: ReactNode[]
  className?: string
  borderRadius?: string
}

const Skeleton: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  className = '',
  borderRadius = '16px 16px 0 0',
  ...props
}) => {
  return (
    <ErrorBoundary>
      <div className={`${s.container} ${className}`} {...props}>
        <Carousel>
          <div className={s.header}>
            <SectionTitleSkeleton />
          </div>
          {children ? (
            <Carousel.Slider className={s.slider} style={{ borderRadius: borderRadius }}>
              {children}
            </Carousel.Slider>
          ) : (
            <Carousel.Slider className={s.slider} style={{ borderRadius: borderRadius }}>
              <div></div>
              <div></div>
            </Carousel.Slider>
          )}
        </Carousel>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
