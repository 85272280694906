const addDays = (date, days) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const apiOwnerMock = {
  api_files_list: {},

  api_files_create: {},

  api_files_read: {},

  api_files_delete: {},

  r_read: {
    id: 508039,
    name: 'Champagne Bar',
    city: 2,
    address: 'Тверская, 3, гостиница The Carlton, Moscow, 1 этаж',
    advert: {
      on: false,
      start: null,
      until: null,
    },

    special: {
      id: 6466,
      modified: '2023-09-19 11:59:27',
      photo: 'https://www.afisha.ru/uploads/images/4/9d/49d2f37844cb417db1de263b44c5e894.JPG',
      published: '2023-09-19 11:59:27',
      text: 'description',
      title: 'test',
      type: 'specialoffer',
      type_verbose: 'Спецпредложение',
      status: 'draft',
      moderation: 'onmoderation',
    },

    menu: {
      id: 6467,
      items: [
        {
          id: 1116,
          title: 'Пицца с цыпленком Буффало',
          description:
            'Пицца с цыпленком Буффало с куриным филе, острым соусом Франк, заправкой Ранч и свежей рукколой',
          photo: 'https://www.afisha.ru/uploads/images/1/b1/1b198fd506ea45978d010926c757734b.jpg',
          price: 790,
        },
        {
          id: 1114,
          title: 'Боул из зеленых овощей',
          description: 'Боул со свежими овощами, листьями салата и заправкой юдзу',
          photo: 'https://www.afisha.ru/uploads/images/f/f3/ff39d94522e04327afea52bd0606db0f.jpg',
          price: 900,
        },
        {
          id: 1115,
          title: 'Крем-суп из тыквы с молоком',
          description:
            'Густой суп-пюре из тыквы с лимонной травой, карри, свежей кинзой, кокосовой стружкой со свежими овощами и тыквенным маслом',
          photo: 'https://www.afisha.ru/uploads/images/b/14/b14b18eb53764eea8786952eb401779a.jpg',
          price: 600,
        },
      ],
      modified: '2023-09-19 11:59:27',
      published: '2023-10-19 11:59:27',
      title: 'Обновление меню',
      type: 'menuoffer',
      type_verbose: 'Обновление меню',
      status: 'draft',
      moderation: 'onmoderation',
    },

    news: {
      id: 6468,
      modified: '2024-07-31 15:10:09',
      photo: 'https://www.afisha.ru/uploads/images/4/9d/49d2f37844cb417db1de263b44c5e894.JPG',
      published: '2023-09-19 17:48:19',
      text: 'В роскошном Champagne Bar новая коллекция авторских коктейлей "Шедевры". Каждый из них как настоящее произведение искусства, гармонично дополняет сценографию бара в лобби отеля: "Синта" с манго и бобами тонка, "Корона" с клубникой и базиликом, "Царский" с мятой, абрикосом и золотом, "Парфюмер" с виноградом и амаро, "Красный принц" с черной смородиной и гранатом — за загадочными названиями скрываются уникальные истории.',
      title: '«Шедевры» в Champagne Bar',
      type: 'newsoffer',
      type_verbose: 'Новость ресторана',
      status: 'published',
      moderation: 'onmoderation',
    },

    can_published: true,
    active_coupon: null,

    owner_cabinet_access: {
      id: 2338,
      created: '2023-09-14 12:36:11',
      created_by_id: 2004590,
      modified: '2023-09-14 12:36:11',
      last_modified_by_id: 2004590,
      city_id: 2,
      show_start: '2023-09-15 00:00:00',
      show_until: '2024-09-15 00:00:00',
    },

    hide_stats: false,
    description: '',
  },

  r_read_stat: [
    {
      city_id: 2,
      city: 'Москва',
      rid: 25457,
      name: 'O2 Restaurant',
      url: '/msk/restaurant/o2-restaurant-25457/',
      feature: 0,
      nearest: 41745,
      similar: 12068,
      search: 11674,
      selections: 47194,
      dynamics: 65487,
      all: 112681,
    },
  ],

  r_info: {
    data: {
      description:
        'В The Carlton Lounge & Bar погрузитесь в атмосферу сдержанной роскоши и наслаждайтесь авторскими завтраками от Шефа в любое время дня, фирменными коктейлями или обновленными чайными церемониями, которые представлены в трех вариантах. Здесь даже самые искушенные гости найдут любимые традиционные закуски с авторскими дополнениями. Сделайте чаепитие настоящей осенней традицией или превратите его в светское мероприятие',
    },
  },

  r_menu_partial_update: {},

  r_menu_item_create: {},

  r_menu_item_partial_update: {},

  r_menu_item_delete: {},

  r_news_partial_update: {},

  r_special_partial_update: {},

  owner_reviews_list: (params) => {
    const listReviews = [
      {
        id: 133431,
        restaurant: 48304, // +++
        author: 2173039, // +++
        author_object: {
          id: 2173039,
          afisha_id: 5626024,
          first_name: 'Валерия',
          last_name: 'Шевченко',
          photo: 'https://lh3.googleusercontent.com/a/AGNmyxb6Swwi9VLaBw5tOMel2P08r_888tA8BQhBYxRE=s96-c',
        },
        body: 'Без...',
        is_positive_like: false,
        thanks_count: 0, // +++
        created: '2023-04-21 12:55:30',
        changed: '2023-04-21 12:55:30', // +++
        comments_number: 0,
        owner_comment_author: 1032879, // +++
        owner_comment_author_object: {
          id: 1032879,
          afisha_id: 4493981,
          first_name: 'Менеджер',
          last_name: 'Раппопорт',
          photo: 'https://avatars.rambler.ru/get/rappoport.manager@rambler.ru/default?c5a91f485d7341759bf04d2143ea8216',
        },
        owner_comment_text: 'Валерия...',
        owner_comment_created: '2023-04-26 16:55:29',
      },
      {
        id: 5404,
        restaurant: 48304, // +++
        author: 1473591, // +++
        author_object: {
          id: 1473591,
          afisha_id: 5139833,
          first_name: 'Ди',
          last_name: 'Камила',
          photo: '',
        },
        body: 'Редко...',
        is_positive_like: true,
        thanks_count: 0, // +++
        created: '2022-02-13 22:33:55',
        changed: '2022-02-13 22:50:43', // +++
        comments_number: 1,
        owner_comment_author: 592060, // +++

        owner_comment_author_object: null,
        // owner_comment_author_object: {
        //   id: 592060,
        //   afisha_id: 2685748,
        //   first_name: 'Рестораны ',
        //   last_name: 'Раппопорта',
        //   photo: 'https://newid.afisha.ru/StaticContent/UserPhoto/e5/f0/3caf6d6bf3aa4d758602a4f7cbbfe5f0.png',
        // },

        owner_comment_text: 'Добрый...',
        owner_comment_created: '2022-02-23 12:16:14',
      },
      {
        id: 5402,
        restaurant: 48304, // +++
        author: 1473594, // +++
        author_object: {
          id: 1473594,
          afisha_id: 5139833,
          first_name: 'Идущий',
          last_name: 'к реке',
          photo: '',
        },
        body: 'Пельмени это очень очень вкусно...',
        is_positive_like: true,
        thanks_count: 0, // +++
        created: '2024-06-13 22:33:55',
        changed: '2022-12-13 22:50:43', // +++
        comments_number: 1,
        owner_comment_author: 592060, // +++
        owner_comment_author_object: null,
        owner_comment_text: 'Внутри много много мяса...',
        owner_comment_created: '2022-02-23 12:16:14',
      },
      {
        id: 6441,
        restaurant: 48304, // +++
        author: 1474294, // +++
        author_object: {
          id: 1474294,
          afisha_id: 5139833,
          first_name: 'Чиназез',
          last_name: 'Сюда',
          photo: '',
        },
        body: 'Опа...',
        is_positive_like: false,
        thanks_count: 0, // +++
        created: '2024-02-17 22:33:55',
        changed: '2024-02-17 22:33:55', // +++
        comments_number: 1,
        owner_comment_author: 592060, // +++
        owner_comment_author_object: null,
        owner_comment_text: 'Апож...',
        owner_comment_created: '2022-02-23 12:16:14',
      },
      {
        id: 5238,
        restaurant: 48304, // +++
        author: 1472294, // +++
        author_object: {
          id: 1472294,
          afisha_id: 5139833,
          first_name: 'Киборг',
          last_name: 'Убийца',
          photo: '',
        },
        body: 'Жареный свинец это вкусно...',
        is_positive_like: true,
        thanks_count: 0, // +++
        created: '2024-06-13 22:33:55',
        changed: '2022-12-13 22:50:43', // +++
        comments_number: 1,
        owner_comment_author: 592060, // +++
        owner_comment_author_object: null,
        owner_comment_text: 'Внутри много много мяса...',
        owner_comment_created: '2022-02-23 12:16:14',
      },
      {
        id: 6442,
        restaurant: 48304, // +++
        author: 1474294, // +++
        author_object: {
          id: 1474294,
          afisha_id: 5139833,
          first_name: 'Чиназез',
          last_name: 'Сюда',
          photo: '',
        },
        body: 'Опа...',
        is_positive_like: false,
        thanks_count: 0, // +++
        created: '2024-02-17 22:33:55',
        changed: '2024-02-17 22:33:55', // +++
        comments_number: 1,
        owner_comment_author: 592060, // +++
        owner_comment_author_object: null,
        owner_comment_text: 'Апож...',
        owner_comment_created: '2022-02-23 12:16:14',
      },
    ]

    // ЛОГИКА ВЫВОДА НЕККОРЕКТНАЯ. НЕ НАДО НА НЕЕ ОРИЕНТИРОВАТЬСЯ КАК НА ПРОДОВСКУЮ

    const isCheckIsOwnerCommentCreated = typeof params.params.owner_comment_created_isnull == 'boolean'
    const isCheckIsPositiveLike = typeof params.params.is_positive_like == 'boolean'

    const filterListByParams = listReviews.filter((el) => {
      if (
        isCheckIsOwnerCommentCreated &&
        params.params.owner_comment_created_isnull === !!el.owner_comment_author_object
      )
        return false
      if (isCheckIsPositiveLike && params.params.is_positive_like !== el.is_positive_like) return false
      return true
    })

    const splitList = filterListByParams.filter((el, index) => {
      const page = params.params.page || 1
      const pageSize = params.params.page_size || 5
      if (index >= (page - 1) * pageSize && index < page * pageSize) return true
      else return false
    })

    const getLengthOwnerUnanswered = listReviews.reduce((total, review) => {
      return !review.owner_comment_author_object ? total + 1 : total
    }, 0)

    const getLengthPositiveReview = listReviews.reduce((total, review) => {
      return review.is_positive_like ? total + 1 : total
    }, 0)

    const result = {
      count: filterListByParams.length,
      next: filterListByParams.length > splitList.length + params.params.offset ? 'next' : null,
      previous: null,

      num_pages: 2,
      current_page_number: 1,
      next_page_number:
        listReviews.length > params.params.page * params.params.page_size ? params.params.page + 1 : null,
      previous_page_number: null,
      page_size: params.params.limit,
      positive_count: getLengthPositiveReview,
      negative_count: listReviews.length - getLengthPositiveReview,
      without_owner_comment_count: getLengthOwnerUnanswered,

      results: splitList,
    }
    return result
  },

  owner_reviews_read: {}, // TODO

  owner_reviews_partial_update: {
    id: 133148,
    restaurant: 48447,
    author: 123,
    author_object: {
      id: 1240454,
      afisha_id: 5038034,
      first_name: 'inqz',
      last_name: '',
      photo: 'https://lh3.googleusercontent.com/a/AATXAJysTEZQcIESQhKbqZo22P9-IBukSeJcCZ0Yl_P4=s96-c',
    },
    body: 'lorem ipsum 123',
    is_positive_like: true,
    thanks_count: 1,
    created: '2022-12-02 13:38:32',
    changed: '2029-12-02 13:38:32',
    comments_number: 0,
    owner_comment_author: 321,
    owner_comment_author_object: {
      id: 1573252,
      afisha_id: 5187114,
      first_name: 'Александр',
      last_name: 'Жуков',
      photo:
        'https://avatars.rambler.ru/enc/a/YjJlOTU0MDkwOWQ4MDViMNjc_GUtMlkkwCLCHjItfp_fXWc4gEclozfT9-yG?b4a7f05eccf0419b92cd6271fde7a92a',
    },
    owner_comment_text: 'lorem 456',
    owner_comment_created: '2023-05-22 20:01:25',
  },
}
