import React, { FC, useEffect, useMemo, useState } from 'react'
import { Title } from '@shared/ui/Typography/Title'
import s from './EditReview.module.sass'
import { IconButton } from '@shared/ui/Actions/IconButton'
import iconThumb from '@icons/icon-thumb.svg'
import { useField } from '@shared/lib/fields/hooks/useField/useField'
import { getDefaultField } from '@shared/lib/fields/getDefaultField'
import { InputHint } from '@shared/ui/Form/Single/InputHint'
import { BuildTextarea } from '@shared/ui/Form/Build/BuildTextarea'
import { Modal } from '@shared/ui/Overlays/Modal'
import { Button } from '@shared/ui/Actions/Button'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { changeComponentEdit, hideComponentEdit } from '@entities/Restaurant/Reviews/model/reducer'
import { sendEditReview } from '@entities/Restaurant/Reviews/model/createAsyncThunk'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { ErrorBoundary } from '../../../../../6_shared/lib/components/ErrorBoundary'

interface Props {
  className?: string
}

const EditReview: FC<Props> = () => {
  const stateReviewEdit = useAppSelector((state) => state.entities.restaurant_reviews.components.edit)
  return stateReviewEdit.visible && <EditReviewInner />
}

const EditReviewInner: FC<Props> = ({ className = '' }) => {
  const stateReviewEdit = useAppSelector((state) => state.entities.restaurant_reviews.components.edit)
  const dispatch = useAppDispatch()
  const [rateError, setRateError] = useState(false)
  const { isMobile } = useClientResponsive()
  const [comment, setComment, validComment] = useField(
    getDefaultField('textarea', {
      name: 'edit',
      value: stateReviewEdit.body,
      required: true,
      validations: {
        'min-symbols-50': 'Отзыв должен содержать более 50 символов',
        'max-symbols-1500': 'Отзыв должен содержать не более 1500 символов',
      },
      settings: {
        placeholder: 'Ваш отзыв',
        height: '135px',
        resize: true,
        label: '',
      },
    }),
  )

  const isLike = stateReviewEdit.is_like === true
  const isDislike = stateReviewEdit.is_like === false

  const handleClickRateDislike = () => {
    dispatch(changeComponentEdit({ is_like: stateReviewEdit.is_like === false ? null : false }))
    setRateError(false)
  }
  const handleClickRateLike = () => {
    dispatch(changeComponentEdit({ is_like: stateReviewEdit.is_like === true ? null : true }))
    setRateError(false)
  }

  useEffect(() => {
    // @ts-ignore
    dispatch(changeComponentEdit({ body: comment.value }))
    // @ts-ignore
  }, [comment.value])

  const validRate = () => {
    return stateReviewEdit.is_like !== null
  }

  const handleSubmit = () => {
    const isValidComment = validComment().error === ''
    const isValidRate = validRate()

    if (isValidComment && isValidRate) {
      dispatch(sendEditReview())
    } else {
      console.error('new review is invalid')
    }
  }

  const handleClose = () => dispatch(hideComponentEdit())

  const classRateLike = useMemo(() => {
    return isLike ? `${s['rate-like']} ${s['active']}` : s['rate-like']
  }, [isLike])

  const classRateDislike = useMemo(() => {
    return isDislike ? `${s['rate-dislike']} ${s['active']}` : s['rate-dislike']
  }, [isDislike])

  return (
    <ErrorBoundary>
      <Modal size={'M'} handleClose={handleClose} title={isMobile && 'Ваш отзыв'} className={s.modal}>
        <div className={`${s.wrapper} ${className}`} data-loading={stateReviewEdit.loading}>
          <div className={s.header}>
            {!isMobile && (
              <Title tag='h3' sizes='h2 h4'>
                Ваш отзыв
              </Title>
            )}
            <div className={s['rate-group']}>
              <IconButton
                icon={iconThumb}
                mode='white-border'
                sizes='XL L'
                active={isDislike}
                onClick={handleClickRateDislike}
                className={classRateDislike}
              />
              <IconButton
                icon={iconThumb}
                mode='white-border'
                sizes='XL L'
                active={isLike}
                onClick={handleClickRateLike}
                className={classRateLike}
              />
              {rateError && (
                <InputHint
                  status='error'
                  text='Поставьте «Нравится» или «Не нравится»'
                  sizes={'M S'}
                  className={s.error}
                />
              )}
            </div>
          </div>
          <form className={s.inner}>
            {/*// @ts-ignore*/}
            <BuildTextarea field={comment} setField={setComment} className={s.textarea} />
            <Button sizes={'XL L'} mode={'black'} onClick={handleSubmit} className={s.button}>
              Сохранить
            </Button>
          </form>
        </div>
        <LoadingSpinner loading={stateReviewEdit.loading} />
      </Modal>
    </ErrorBoundary>
  )
}

export default EditReview
