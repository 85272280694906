import s from './RestSelectionItem.module.sass'

import React, { FC } from 'react'
import Link from 'next/link'

import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  name: string
  address: string
  slug: string
}

const RestSelectionItem: FC<Props> = ({ name, address, slug }) => {
  return (
    <ErrorBoundary>
      <Link className={s.link} href={slug}>
        <Text sizes={'ML'} className={s.name}>
          {name}
        </Text>
        <Text sizes={'S'} className={s.address}>
          {address}
        </Text>
      </Link>
    </ErrorBoundary>
  )
}

export default RestSelectionItem
