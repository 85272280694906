/**
 * @swagger http://rests.afisha.ru/rests/api/public/v1/swagger/
 */

import { apiPublicMock } from './apiPublicMock'

import * as Types from '@shared/api/types/apiPublicParameters'
import parseCookie from '@shared/lib/helpers/parseCookie'
import { apiRequest } from '@shared/api/apiRequest'
import { mappingAPI } from '@shared/api/middleware/mappingAPI'
import { middlewareError, textErrorsApiPublic } from '@shared/api/middleware/middlewareError'
import { API_GRAPH } from '@shared/api/api.gql'
import { PropsChangeFavorite } from '@shared/api/types/apiPublicParameters'

const BASE_DOMAIN = process.env.IS_STAGE === 'true' ? 'http://artemis.dev.rests.afisha.ru' : 'https://www.afisha.ru'
const BASE_URL = `${BASE_DOMAIN}/rests/api/public/v1`
const BOOKING_TOKEN = 'BookingPartnerToken 072b6f56b28f9a8c600775143f9f923779406256' // TODO: to .env
const GITLAB_STATUS = process.env.GITLAB_STATUS
const IS_PROD = GITLAB_STATUS === '0'

// TODO: IS_TEMP_FIX - временное ограничение вывода ошибки, необходимо сделать так,
// чтобы она не пыталась отобразиться если пользователь еще не авторизован,
// например можно проверять наличие cookie, через какой-нибудь middleware, и только потом делать запрос user/me
const IS_TEMP_FIX = false

export const API_PUBLIC = {
  // BOOKING

  booking_create: async (props: Types.PropsBookingCreate) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['booking_create']
    return mappingAPI
      .booking_create(
        apiRequest.post({
          url: `${BASE_URL}/booking/`,
          headers: {
            Authorization: BOOKING_TOKEN,
          },
          ...props,
        }),
      )
      .catch((error) => {
        const serveErrorMsg = error.message
        middlewareError(showErrorUser, serveErrorMsg || textError)
      })
  },

  booking_get_slots: (props: Types.PropsGetSlots) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['booking_get_slots']
    const targetRest = props.rest
    const queryParams = `?num_of_places=${props.numOfPlaces}&date=${props.date}`
    return mappingAPI
      .booking_get_slots(apiRequest.get({ url: `${BASE_URL}/restaurant/${targetRest}/slots/${queryParams}`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  booking_discount: (props: Types.PropsBookingDiscount) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['booking_discount']
    return mappingAPI
      .booking_discount(apiRequest.post({ url: `${BASE_URL}/booking/discount/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  booking_restaurants_list: (props?: Types.PropsBookingRestaurantsList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['booking_restaurants_list']
    return mappingAPI
      .booking_restaurants_list(apiRequest.get({ url: `${BASE_URL}/booking_restaurants/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // CITY

  city_list: (props?: Types.PropsCityList) => {
    const params = {
      params: {
        page: props?.params?.page || 1,
        page_size: props?.params?.page_size || 1000,
      },
    }

    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['city_list']
    return mappingAPI
      .city_list(apiRequest.get({ url: `${BASE_URL}/city/`, ...props, ...params }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  city_read: (props: Types.PropsCityRead) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['city_read']
    const targetCitySlug = props.path || 'msk'
    return mappingAPI
      .city_read(apiRequest.get({ url: `${BASE_URL}/city/${targetCitySlug}/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // METRO

  metro_list: (props?: Types.PropsMetroList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['metro_list']
    return mappingAPI
      .metro_list(apiRequest.get({ url: `${BASE_URL}/metro/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // NEWS

  news_list: (props?: Types.PropsNewsList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['news_list']
    return mappingAPI
      .news_list(apiRequest.get({ url: `${BASE_URL}/news/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  news_read: (props: Types.PropsNewsRead) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['news_read']
    return mappingAPI
      .news_read(apiRequest.get({ url: `${BASE_URL}/news/${props.path}/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // PHOTO

  photo_list: (props: Types.PropsPhotoList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['photo_list']
    return mappingAPI
      .photo_list(apiRequest.get({ url: `${BASE_URL}/photo/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // RESTAURANT

  restaurant_list: async (props?: Types.PropsRestaurantList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['restaurant_list']
    return mappingAPI
      .restaurant_list(apiRequest.get({ url: `${BASE_URL}/restaurant/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  restaurant_read: (props: Types.PropsRestaurantRead) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['restaurant_read']
    return mappingAPI
      .restaurant_read(apiRequest.get({ url: `${BASE_URL}/restaurant/${props.path}/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  restaurant_nearest_rests: (props: Types.PropsRestaurantNearestRests) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['restaurant_nearest_rests']
    return mappingAPI
      .restaurant_nearest_rests(
        apiRequest.get({ url: `${BASE_URL}/restaurant/${props.path}/nearest_rests/`, ...props }),
      )
      .catch(() => middlewareError(showErrorUser, textError))
  },

  restaurant_popular_rests: (props: Types.PropsRestaurantPopularRests) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['restaurant_popular_rests']
    return mappingAPI
      .restaurant_popular_rests(
        apiRequest.get({ url: `${BASE_URL}/restaurant/${props.path}/popular_rests/`, ...props }),
      )
      .catch(() => middlewareError(showErrorUser, textError))
  },

  restaurant_similar_rests: (props: Types.PropsRestaurantSimilarRests) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['restaurant_similar_rests']
    return mappingAPI
      .restaurant_similar_rests(
        apiRequest.get({ url: `${BASE_URL}/restaurant/${props.path}/similar_rests/`, ...props }),
      )
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // REVIEWS

  reviews_list: (props?: Types.PropsReviewsList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['reviews_list']
    return mappingAPI
      .reviews_list(apiRequest.get({ url: `${BASE_URL}/reviews/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  reviews_create: (props: Types.PropsReviewsCreate) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['reviews_create']
    return mappingAPI
      .reviews_create(
        apiRequest.post({
          url: `${BASE_URL}/reviews/`,
          ...props,
        }),
      )
      .catch(() => middlewareError(showErrorUser, textError))
  },

  reviews_partial_update: (props: Types.PropsReviewsPartialUpdate) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['reviews_partial_update']
    return mappingAPI
      .reviews_partial_update(apiRequest.patch({ url: `${BASE_URL}/reviews/${props.path}/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  reviews_update: (props: Types.PropsReviewsUpdate) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['reviews_update']
    return mappingAPI
      .reviews_update(apiRequest.put({ url: `${BASE_URL}/reviews/${props.path}/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  reviews_delete: (props: Types.PropsReviewsDelete) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['reviews_delete']
    return mappingAPI
      .reviews_delete(apiRequest.delete({ url: `${BASE_URL}/reviews/${props.path}/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // SELECTION

  selection_list: (props?: Types.PropsSelectionList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['selection_list']
    return mappingAPI
      .selection_list(apiRequest.get({ url: `${BASE_URL}/selection/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  selection_read: (props: Types.PropsSelectionRead) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['selection_read']
    return mappingAPI
      .selection_read(apiRequest.get({ url: `${BASE_URL}/selection/${props.path}/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  selection_restaurants: (props: Types.PropsSelectionRestaurants) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['selection_restaurants']
    return mappingAPI
      .selection_restaurants(apiRequest.get({ url: `${BASE_URL}/selection/${props.path}/restaurants/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // SEO

  seo_read: (props: Types.PropsSeoRead) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['seo_read']
    return mappingAPI
      .seo_read(apiRequest.get({ url: `${BASE_URL}/seo/${props.path}`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // TAG

  tag_list: (props?: Types.PropsTagList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['tag_list']
    return mappingAPI
      .tag_list(apiRequest.get({ url: `${BASE_URL}/tag/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // USER

  user_logout: (props: Types.PropsUserLogout) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['user_logout']
    const thisMock = apiPublicMock['user_logout']

    return mappingAPI
      .user_logout(
        IS_PROD
          ? apiRequest.post({ url: `${BASE_URL}/user/logout/`, ...props })
          : new Promise((resolve, reject) => {
              resolve(thisMock)
            }),
      )
      .catch(() => middlewareError(showErrorUser, textError))
  },

  user_me: async (props?: Types.PropsUserMe) => {
    const showErrorUser = IS_TEMP_FIX && (props?.error?.showUser || false)
    const textError = props?.error?.text || textErrorsApiPublic['user_me']
    const thisMock = apiPublicMock['user_me']

    const prodRequest = () =>
      apiRequest.get({ url: `${BASE_URL}/user/me/`, ...props }).then(async (res) => {
        const variables = { id: `User_${res.afisha_id}`, first: 999 }
        const resultGraph = await API_GRAPH.favorites.load(variables)
        return { ...res, favoritesGraph: resultGraph }
      })

    const devRequest = () =>
      API_GRAPH.favorites
        .load({ id: 'User_5931952', first: 999 })
        .then((resultGraph) => {
          return { ...thisMock, favoritesGraph: resultGraph }
        })
        .catch(() => ({ ...thisMock }))

    return mappingAPI
      .user_me(IS_PROD ? prodRequest() : devRequest())
      .catch(() => middlewareError(showErrorUser, textError, false))
  },

  // VARIETY

  variety_list: (props?: Types.PropsVarietyList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['variety_list']
    return mappingAPI
      .variety_list(apiRequest.get({ url: `${BASE_URL}/variety/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // VIDEO

  video_list: (props?: Types.PropsVideoList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['video_list']
    return mappingAPI
      .video_list(apiRequest.get({ url: `${BASE_URL}/video/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // COMPLAIN

  /**
   * @function send_mistake - отправить обращение по ошибке
   */
  send_mistake: async (props: Types.PropsSendMistake) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['send_mistake']
    return mappingAPI
      .send_mistake(
        apiRequest.post({
          url: `${BASE_DOMAIN}/rests/api/complain/`,
          headers: {
            Authorization: BOOKING_TOKEN,
          },
          ...props,
        }),
      )
      .catch(() => middlewareError(showErrorUser, textError))
  },

  /**
   * @function send_new_rest - отправить форму для нового ресторана
   */
  send_new_rest: async (props: Types.PropsSendNewRest) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiPublic['send_new_rest']
    return apiRequest
      .post({
        url: `${BASE_DOMAIN}/rests/api/complain/`,
        headers: {
          'X-CSRFToken': parseCookie('csrftoken'),
        },
        ...props,
      })
      .catch(() => middlewareError(showErrorUser, textError))
  },

  // END
}
