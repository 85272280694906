import * as React from 'react'
import { useTooltipContext } from '@shared/ui/Overlays/Tooltip/hooks'
import { useMergeRefs } from '@floating-ui/react'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

const TooltipTrigger = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & { asChild?: boolean }>(
  function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
    const context = useTooltipContext()
    const childrenRef = (children as any).ref
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && React.isValidElement(children)) {
      return React.cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        }),
      )
    }

    return (
      <ErrorBoundary>
        <button
          ref={ref}
          // The user can style the trigger based on the state
          data-state={context.open ? 'open' : 'closed'}
          {...context.getReferenceProps(props)}>
          {children}
        </button>
      </ErrorBoundary>
    )
  },
)

export default TooltipTrigger
