import React, { FC, memo } from 'react'
import { useRouter } from 'next/router'
import s from './RestaurantHomeFilters.module.sass'
import { useAppSelector } from '@app/model/store'
import { RestaurantQuickFilter } from '@widgets/Restaurant/RestaurantQuickFilter'
import { RestaurantFilters } from '@features/Restaurant/RestaurantFilters'
import { TypeOutput } from '@features/Restaurant/RestaurantFilters/model/types'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { SectionTitle } from '@shared/ui/Layout/SectionTitle'

const RestaurantHomeFilters: FC = () => {
  const router = useRouter()
  const stateGlobal = useAppSelector((state) => state.global)

  const handleFilterData = (data: TypeOutput) => {
    router.push(
      `/${router.query.city_slug}/restaurants/restaurant_list/${data.urlQuery}`,
      `/${router.query.city_slug}/restaurants/restaurant_list/${data.urlQuery}`,
      { scroll: false },
    )
  }

  return (
    <ErrorBoundary>
      <div>
        <SectionTitle
          title={`Все кафе и рестораны ${stateGlobal.cityRead?.genitive_case}`}
          tagTitle={'h1'}
          link={`/${stateGlobal.cityRead?.slug}/restaurants/restaurant_list/`}
          className={s['quick-filter-title']}
          skeleton={!stateGlobal.cityRead}
        />
        <RestaurantFilters preloadingResults={false} selected={null} handleOutputData={handleFilterData} />
        <RestaurantQuickFilter className={s['quick-filter']} />
      </div>
    </ErrorBoundary>
  )
}

export default memo(RestaurantHomeFilters)
