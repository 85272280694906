/**
 * @fsd_page Listing - галерея, список ресторанов с фильтрацией
 *   - all_rests_listing - список всех ресторанов
 *   - search_rests_listing - список ресторанов найденных по поиску
 *   - tag_rests_listing - рестораны по тегу
 */

import s from './Listing.module.sass'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { loadMorePage, loadPage, updateTitleAndDescriptionAsync } from '@pages/Listing/model/createAsyncThunk'
import { SelectionsSection } from '@pages/Listing/ui/SelectionsSection'
import { setList, setLoading, setPagination, setParamsRequest } from '@pages/Listing/model/reducer'

import { RestCard, RestCardSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard'

import { Pagination } from '@features/Common/Pagination'
import { TypeOutput } from '@features/Restaurant/RestaurantFilters/model/types'
import { RestaurantFilters } from '@features/Restaurant/RestaurantFilters'

import { ContainerLongLeft } from '@shared/ui/Layout/Containers/ContainerLongLeft'
import { ContainerTwoColumns } from '@shared/ui/Layout/Containers/ContainerTwoColumns'
import { EGG } from '@shared/api/analytics'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import { AdvertSidebarSticky } from '@shared/ui/Layout/AdvertTemplate/ui/AdvertSidebarSticky'
import { ListingDescriptionPage } from '@pages/Listing/ui/ListingDescriptionPage'

const isAdvVisible = (currentIndex) => {
  const columnsCardsByWidthWindow = { '1592': 12, '541': 12, '0': 10 }
  const currentWidthWindow = window.innerWidth

  let currentColumns = null
  if (currentWidthWindow >= 1592) currentColumns = columnsCardsByWidthWindow['1592']
  else if (currentWidthWindow >= 541) currentColumns = columnsCardsByWidthWindow['541']
  else currentColumns = columnsCardsByWidthWindow['0']

  return currentIndex !== 0 && currentIndex % currentColumns === 0
}
const getListWithAdv = (list) => {
  const result = []

  list?.forEach((restCard, index) => {
    if (isAdvVisible(index)) result.push({ type: 'advert' })
    result.push({ type: 'restCard', data: restCard })
  })

  return result
}

const Listing = () => {
  const { isMobile, isTablet } = useClientResponsive()
  const globalStore = useAppSelector((store) => store)
  const { pagination, list, loading, selections, paramsRequest } = globalStore.pages.listing
  const dispatch = useAppDispatch()
  const router = useRouter()

  // @ts-ignore
  const isSpecialRests = !!paramsRequest?.has_special

  // * pagination const *
  const currentPageNumber = pagination?.page.current.number

  const handleLoadingFilter = useCallback((loadingProps) => {
    dispatch(setLoading({ ...loading, items_full: loadingProps }))
  }, [])

  const handleFilterData = useCallback(
    (data: TypeOutput) => {
      dispatch(updateTitleAndDescriptionAsync(data.slugs))
      router.replace(`/${router.query.city_slug}/restaurants/restaurant_list/${data.urlQuery}`, null, {
        shallow: true,
      })

      dispatch(setParamsRequest(data.paramsRequest))
      // @ts-ignore
      dispatch(setList(data.restaurants))
      // @ts-ignore
      dispatch(setPagination(data.pagination))
    },
    [router.query.city_slug],
  )

  const getFiltersSlugs = (router) => {
    return router.query.rests_tags
  }

  const [filtersSlug, setFiltersSlug] = useState(() => getFiltersSlugs(router))

  const handleRouteChangeComplete = (url, { shallow }) => {
    const urlArray = url.split('/')
    const hasRestaurantList = urlArray.findIndex((item) => item === 'restaurant_list')
    if (hasRestaurantList) {
      const tags = urlArray.slice(hasRestaurantList)
      !shallow && setFiltersSlug(() => tags)
    }
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [globalStore])

  useEffect(() => {
    if (globalStore.features.restaurant_filters.ready) {
      EGG.pages.Listing.appear_screen_page()
    }
  }, [globalStore.features.restaurant_filters.ready, router.asPath])

  const isShowSelections = !isMobile && (selections.loading || (!selections.loading && !!selections.list?.length))

  const loadingRestsList = loading.page || loading.items_full
  const isShowListRests = loadingRestsList || (!loadingRestsList && !!list?.length)

  const listGrid = useMemo(() => {
    return getListWithAdv(list).map((item, index) => {
      if (item.type === 'advert') {
        return (
          <div className={s['advertListing']} key={`advert-${index}`} data-listing={'test'}>
            <AdvertTemplate type={'100%x240 Listing1'} count={index} className={s.advertListing} />
          </div>
        )
      }

      if (item.type === 'restCard') {
        return (
          <RestCard
            key={item.data.id}
            type={isSpecialRests ? 'special-offer' : 'list'}
            sizes={isSpecialRests ? 'L' : 'L S'}
            data={item.data}
            className={s.card}
            analytic={{
              advertCardPlaceType: 'search',
              section_name: 'Листинг',
              section_index: index,
            }}
          />
        )
      }

      return <></>
    })
  }, [list, isSpecialRests])

  return (
    <ErrorBoundary>
      <ContainerTwoColumns>
        <ContainerTwoColumns.Main>
          <ListingDescriptionPage />

          <RestaurantFilters
            // @ts-ignore
            selected={filtersSlug}
            handleOutputData={handleFilterData}
            handleLoading={handleLoadingFilter}
          />

          <div className={s['rest-list-layout']} data-is-special={isSpecialRests}>
            {isShowListRests ? (
              loadingRestsList ? (
                <ul className={s['rest-list-grid']}>
                  {Array.from(Array(9).keys()).map((i, idx) => (
                    <RestCardSkeleton key={idx} sizes={'L S'} className={s.card} type={'list'} />
                  ))}
                </ul>
              ) : (
                <ul className={s['rest-list-grid']}>{listGrid}</ul>
              )
            ) : (
              <div>По вашему запросу ничего не найдено...</div>
            )}

            <div className={s.pagination} data-has-pagination={pagination?.page.count > 1}>
              <Pagination
                mode={'all'}
                autoloadingOnMobile={true}
                currentPage={currentPageNumber}
                pageCount={pagination?.page.count}
                handleLoadMore={() => dispatch(loadMorePage())}
                // @ts-ignore
                handleSelectPage={(page) => dispatch(loadPage(page))}
                analytic={{ section_name: 'Листинг' }}
              />
            </div>
          </div>

          {!isMobile && !isTablet && <AdvertTemplate type={'Parallax'} />}
        </ContainerTwoColumns.Main>

        {!isMobile && !isTablet && (
          <ContainerTwoColumns.Aside className={s.columnAdvert}>
            <AdvertTemplate type={'240x400'} container={AdvertSidebarSticky} />
            {list?.length >= (isTablet ? 12 : 16) && (
              <AdvertTemplate type={'240x400_2nd'} container={AdvertSidebarSticky} />
            )}
          </ContainerTwoColumns.Aside>
        )}
      </ContainerTwoColumns>

      {/*Подборки Афиши Рестораны*/}
      {isShowSelections && (
        <ContainerLongLeft>
          <SelectionsSection selectionsList={selections.list} skeleton={selections.loading} />
        </ContainerLongLeft>
      )}

      {!isMobile && !isTablet && <AdvertTemplate type={'Content1'} container={ContainerLongLeft} />}
    </ErrorBoundary>
  )
}

export default Listing
