import s from './OwnerGuideBlock.module.sass'

import React from 'react'

import { Text } from '@shared/ui/Typography/Text'
import { Button } from '@shared/ui/Actions/Button'

import IconGuide from '@icons/icon-guide.svg'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

/**
 * @function OwnerGuideBlock
 */
const OwnerGuideBlock = () => {
  return (
    <ErrorBoundary>
      <div className={s['guide-block']}>
        <div className={s['guide-yellow-circle']}>
          <div className={s['guide-icon-wrap']}>
            <IconGuide />
          </div>
        </div>

        <div className={s['guide-yellow-block']}>
          <Text sizes={'S'} className={s['guide-text']}>
            Как создать хорошее объявление?
            <br />
            Подготовили для вас рекомендации с&nbsp;актуальными примерами
          </Text>

          <a
            href={'https://www.afisha.ru/rests/guidelines_for_restaurateurs.pdf'}
            target='_blank'
            rel='noopener noreferrer'>
            <Button className={s['guide-button']} sizes={'M'} mode={'yellow-border'}>
              Посмотреть
            </Button>
          </a>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default OwnerGuideBlock
