/**
 * @fsd_page SelectionGallery - список подборок
 */

import React, { FC, useEffect, useMemo, useState } from 'react'
import s from './SelectionsGallery.module.sass'

import { ContainerTwoColumns } from '@shared/ui/Layout/Containers/ContainerTwoColumns'
import { Title } from '@shared/ui/Typography/Title'
import { useAppDispatch, useAppSelector } from '@app/model/store'

import { CompilationGrid, CompilationGridSkeleton } from '@widgets/News/CompilationGrid'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ContainerLongLeft } from '@shared/ui/Layout/Containers/ContainerLongLeft'

import { loadMorePage } from '@pages/SelectionsGallery/model/createAsyncThunk'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { SelectionCard, SelectionCardSkeleton } from '@widgets/Selection/SelectionCard'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'
import { RestCard, RestCardSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard'
import { SectionMainCarousel } from '@shared/ui/Layout/SectionMainCarousel'
import { SelectionMainCard } from '@widgets/Selection/SelectionMainCard'
import { getListSection } from '@shared/lib/helpers/getListSection'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'
import { EGG } from '@shared/api/analytics'
import { Pagination } from '@features/Common/Pagination'
import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import { AdvertSidebarSticky } from '@shared/ui/Layout/AdvertTemplate/ui/AdvertSidebarSticky'
import { MainCardSkeleton } from '@shared/ui/Layout/Cards/MainCard'
import { NewsCardCover } from '@widgets/News/NewsCardCover'
import { SelectionCardCover } from '@widgets/Selection/SelectionCardCover'

const SelectionsGallery: FC = () => {
  const dispatch = useAppDispatch()
  const stateGlobal = useAppSelector((state) => state.global)
  const { loading, pagination, list, sections } = useAppSelector((state) => state.pages.selections_gallery)
  const responsive = useClientResponsive()
  const { isMobile, isTablet } = responsive
  const [listSection, setListSection] = useState([])

  useEffect(() => {
    const isLastPage = !pagination?.page.next.number
    setListSection(getListSection(list, responsive, isLastPage))
  }, [list, responsive])

  // после какого кол-ва секций показывать специальный блок
  const specSectionPosition = useMemo(() => {
    const result = {
      desktop: 4,
      mobile: 12,
    }
    // на этом разрешении карточки выстраиваются в две колонки
    if (window.innerWidth <= 500) {
      result.mobile = 11
      // в три колонки
    } else if (window.innerWidth <= 650) {
      result.mobile = 12
      // в четыре колонки
    } else if (window.innerWidth <= 757) {
      result.mobile = 13
    }

    const hasSlider = listSection.some((section) => section === 'slider')
    const hasAdvert = listSection.some((section) => section === 'advert')

    if (hasSlider) result.mobile++
    if (hasAdvert) result.mobile++

    return result
  }, [listSection.length])

  const hasSlider = listSection.some((section) => section === 'slider') ? 0 : 1
  const isShowTwoPartListMobile = isMobile && listSection.length - 1 > specSectionPosition.mobile - hasSlider
  const isShowTwoPartListDesktop = !isMobile && listSection.length - 1 > specSectionPosition.desktop
  const isShowTwoPart = isShowTwoPartListMobile || isShowTwoPartListDesktop
  const isShowMainSlider = listSection[0]?.type === 'slider'

  useEffectOnce(() => {
    EGG.pages.SelectionsGallery.appear_screen_page()
  })

  const sectionNameForProps = `Галерея подборок`

  const getAnalyticIndexes = (section) => {
    return section.list.map((el) => list.findIndex((findItem) => findItem.id === el.id))
  }

  const isShowRecommendedRests =
    sections.recommendedRests.loading ||
    (!sections.recommendedRests.loading && !!sections.recommendedRests.list?.length)

  const isSkeletonTitle = loading.page || !stateGlobal.cityRead?.genitive_case

  return (
    <ErrorBoundary>
      <main className={s.body}>
        {isShowMainSlider && (
          <SectionMainCarousel>
            {listSection[0].list.map((data) =>
              listSection[0].skeleton ? (
                <MainCardSkeleton />
              ) : (
                <SelectionMainCard
                  data={data}
                  key={data.id}
                  analytic={{
                    section_name: sectionNameForProps,
                    section_index: list.findIndex((findItem) => findItem.id === data.id),
                  }}
                />
              ),
            )}
          </SectionMainCarousel>
        )}

        <ContainerTwoColumns>
          <ContainerTwoColumns.Main>
            <div className={`${s.header} ${!isShowMainSlider ? s['header--margin-top'] : ''}`}>
              <Title sizes={'h2 h4'} skeleton={isSkeletonTitle}>
                {/* @ts-ignore*/}
                Подборки лучших ресторанов и кафе {stateGlobal.cityRead?.genitive_case}
              </Title>
            </div>

            <div className={s.list} data-loading={loading.items_full}>
              {listSection.map((section, index) => {
                if (isMobile && index > specSectionPosition.mobile) return
                if (!isMobile && index > specSectionPosition.desktop) return

                if (!isMobile && section.type === 'grid') {
                  return section.skeleton ? (
                    <CompilationGridSkeleton counts={'6 4 4'} className={s.grid} />
                  ) : (
                    <CompilationGrid
                      type={'selections'}
                      key={section.list[0].id}
                      list={section.list}
                      counts={'6 4 4'}
                      className={s.grid}
                      analytic={{
                        section_name: sectionNameForProps,
                        analytic_indexes: getAnalyticIndexes(section),
                      }}
                    />
                  )
                } else if (section.type === 'cards') {
                  if (isMobile) {
                    return section.list.map((item, index) =>
                      section.skeleton ? (
                        <SelectionCardSkeleton key={`${index}-cards`} sizes={'S1x1 S1x1 1x1 1x1'} />
                      ) : (
                        <SelectionCard
                          key={item.id}
                          sizes={'S1x1 S1x1 1x1 1x1'}
                          className={s.card}
                          data={item}
                          analytic={{
                            section_name: sectionNameForProps,
                            section_index: list.findIndex((findItem) => findItem.id === item.id),
                          }}
                        />
                      ),
                    )
                  } else {
                    return (
                      <div className={s.cards}>
                        {section.list.map((item) =>
                          section.skeleton ? (
                            <SelectionCardSkeleton key={`${index}-cards`} sizes={'S1x1 S1x1 1x1 1x1'} />
                          ) : (
                            <SelectionCard
                              key={item.id}
                              sizes={'S1x1 S1x1 1x1 1x1'}
                              className={s.card}
                              data={item}
                              analytic={{
                                section_name: sectionNameForProps,
                                section_index: list.findIndex((findItem) => findItem.id === item.id),
                              }}
                            />
                          ),
                        )}
                      </div>
                    )
                  }
                } else if (section.type === 'advert') {
                  return (
                    <AdvertTemplate type={section.componentType} count={section.count} className={s.advertInGallery} />
                  )
                }
              })}
            </div>
          </ContainerTwoColumns.Main>

          {!isMobile && !isTablet && (
            <ContainerTwoColumns.Aside>
              <AdvertTemplate type={'240x400'} container={AdvertSidebarSticky} />
            </ContainerTwoColumns.Aside>
          )}
        </ContainerTwoColumns>

        {(loading.items_full || sections.special) && (
          <ContainerLongLeft data-loading={loading.items_full}>
            <SelectionCardCover
              data={sections.special}
              subtitles={[sections.special.description.restaurantsCount + ' ресторанов']}
              buttonText={'Выбрать ресторан'}
              analytic={{
                section_index: 18,
                section_name: `Галерея подборок`,
              }}
              skeleton={loading.items_full}
            />
          </ContainerLongLeft>
        )}

        {/* Рекомендации для вас, (рестораны) */}
        {isShowRecommendedRests && (
          <ContainerLongLeft>
            <section>
              <SectionCarousel
                countSlidesOnContainer={3}
                title={'Рекомендации для вас'}
                skeleton={sections.recommendedRests.loading}>
                <SectionCarousel.Items>
                  {sections.recommendedRests.list?.map((item, index) => (
                    <RestCard
                      key={item.id}
                      data={item}
                      sizes={'L ML'}
                      type={'list'}
                      analytic={{
                        advertCardPlaceType: null,
                        // @ts-ignore
                        section_name: `Рекомендации`,
                        section_index: index,
                      }}
                    />
                  ))}
                </SectionCarousel.Items>
                <SectionCarousel.Skeletons>
                  {Array.from(Array(4).keys()).map((i, idx) => (
                    <RestCardSkeleton key={idx} sizes={'L ML'} type={'list'} />
                  ))}
                </SectionCarousel.Skeletons>
              </SectionCarousel>
            </section>
          </ContainerLongLeft>
        )}

        {isShowTwoPart && (
          <ContainerTwoColumns>
            <ContainerTwoColumns.Main data-loading={loading.items_full}>
              <div className={s.list}>
                {listSection.map((section, index) => {
                  if (isMobile && index <= specSectionPosition.mobile) return
                  if (!isMobile && index <= specSectionPosition.desktop) return
                  if (!isMobile && section.type === 'grid') {
                    return section.skeleton ? (
                      <CompilationGridSkeleton counts={'6 4 4'} className={s.grid} />
                    ) : (
                      <CompilationGrid
                        type={'selections'}
                        key={section.list[0].id}
                        list={section.list}
                        counts={'6 4 4'}
                        className={s.grid}
                        analytic={{
                          section_name: sectionNameForProps,
                          analytic_indexes: getAnalyticIndexes(section),
                        }}
                      />
                    )
                  } else if (section.type === 'cards') {
                    return isMobile ? (
                      section.list.map((item) =>
                        section.skeleton ? (
                          <SelectionCardSkeleton key={`${index}-cards`} sizes={'S1x1 S1x1 1x1 1x1'} />
                        ) : (
                          <SelectionCard
                            key={item.id}
                            sizes={'S1x1 S1x1 1x1 1x1'}
                            className={s.card}
                            data={item}
                            analytic={{
                              section_name: sectionNameForProps,
                              section_index: list.findIndex((findItem) => findItem.id === item.id),
                            }}
                          />
                        ),
                      )
                    ) : (
                      <div className={s.cards}>
                        {section.list.map((item) =>
                          section.skeleton ? (
                            <SelectionCardSkeleton key={`${index}-cards`} sizes={'S1x1 S1x1 1x1 1x1'} />
                          ) : (
                            <SelectionCard
                              key={item.id}
                              sizes={'S1x1 S1x1 1x1 1x1'}
                              className={s.card}
                              data={item}
                              analytic={{
                                section_name: sectionNameForProps,
                                section_index: list.findIndex((findItem) => findItem.id === item.id),
                              }}
                            />
                          ),
                        )}
                      </div>
                    )
                  } else if (section.type === 'advert') {
                    return (
                      <AdvertTemplate
                        type={section.componentType}
                        count={section.count}
                        className={s.advertInGallery}
                      />
                    )
                  }
                })}
              </div>
            </ContainerTwoColumns.Main>
          </ContainerTwoColumns>
        )}

        <ContainerTwoColumns>
          <ContainerTwoColumns.Main className={s.footer}>
            <Pagination
              mode={'load-more'}
              autoloadingOnMobile={true}
              currentPage={pagination?.page.current.number}
              pageCount={pagination?.page.count}
              handleLoadMore={() => dispatch(loadMorePage())}
              analytic={{ section_name: 'Галерея подборок' }}
              className={s.pagination}
            />
          </ContainerTwoColumns.Main>
        </ContainerTwoColumns>
      </main>
    </ErrorBoundary>
  )
}

export default SelectionsGallery
