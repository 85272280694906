import s from './RestaurantCover.module.sass'
import React, { FC, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { openBooking } from '@widgets/Restaurant/Booking'
import { RestaurantCoverSchedule } from './ui/RestaurantCoverSchedule'
import { Cover } from '@shared/ui/Layout/Cover'
import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'
import { Text } from '@shared/ui/Typography/Text'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Rating } from '@shared/ui/Feedback/Rating'
import { PriceRange } from '@shared/ui/Feedback/PriceRange'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { RestaurantCoverSkeleton } from '@widgets/Restaurant/RestaurantCover/index'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { PromoBadgeCTA } from '@shared/ui/Feedback/PromoBadges/PromoBadgeCTA'
import { ChevronWithTooltip } from '@shared/ui/Feedback/ChevronWithTooltip'
import { ContainerLong } from '@shared/ui/Layout/Containers/ContainerLong'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { numWord } from '@widgets/Restaurant/Booking/lib/helper'
import { scrollToElem } from '@shared/lib/helpers/scrollToElem'
import { Favorite } from '@features/Favorite'
import { EGG } from '@shared/api/analytics'
import { useRouter } from 'next/router'

import IconComment from '@icons/icon-comment.svg'
import IconChevron from '@icons/icon-chevron-big.svg'
import IconLike from '@icons/icon-like.svg'

const RestaurantCoverWrapper: FC = () => {
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item)

  return stateRestaurant.data ? <RestaurantCover /> : <RestaurantCoverSkeleton classNameContainer={s.container} />
}

const RestaurantCover: FC = () => {
  const dispatch = useAppDispatch()
  const stateGlobal = useAppSelector((state) => state.global)
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item)
  const router = useRouter()
  const { isMobile } = useClientResponsive()

  const {
    id,
    name,
    type,
    status,
    categories,
    images,
    booking,
    address,
    feedback,
    tags,
    schedule,
    price,
    url,
    discounts,
  } = stateRestaurant.data

  // @ts-ignore
  const img = images.cover.rest1894x1000 || images.main
  const fullAddress = `${stateGlobal.currentCity.name}, ${address.address}`
  const countReviews = feedback.reviews.count
  const countReviewsText = `${countReviews} ${numWord(countReviews, ['отзыв', 'отзыва', 'отзывов'])}`

  const handleBooking = () => {
    if (booking.afisha.active) {
      dispatch(openBooking(stateRestaurant.data as TypeMappingRestOutput))
    } else if (booking.tomesto.active) {
      window.open(booking.tomesto.url, '_blank')
    }

    EGG.entity.restaurant.click_booking(stateRestaurant.data as TypeMappingRestOutput, { section_name: 'page' })
  }

  // в приоритете перейти на листинг типа кухни,
  // если его нет то на тип ресторана,
  const getSimilarRestsLink = useMemo((): string | null => {
    let link = null
    const kitchenType = tags.searchable.find((itemTag) => itemTag.name.includes('кухня'))
    const restaurantType = tags.searchable.find((itemTag) => itemTag.name.includes(type))
    if (kitchenType) {
      link = kitchenType.url
    } else if (restaurantType) {
      link = restaurantType.url
    } else {
      link = tags?.searchable[0]?.url || null
    }

    if (link) {
      const url = link.split('/')
      url.splice(1, 1, stateGlobal.currentCity.slug)
      return url.join('/')
    } else {
      return null
    }
  }, [])

  const handleClickScrollToReviews = () => {
    EGG.pages.RestItem.button_to_reviews_click()
    scrollToElem('reviews')
  }

  const isShowImg = [0, 2, 3].includes(status.code) && !!img
  const isShowLikeButton = [0, 2, 3].includes(status.code)
  const isShowReviewsButton = [0, 2].includes(status.code)
  const isShowActionButtons = isShowLikeButton || isShowReviewsButton
  const isShowRating = [0, 1, 2, 10].includes(status.code) && (feedback.rating >= 4 || countReviews > 0)
  const isShowChevron = [0, 1, 2, 10].includes(status.code) && categories.selection
  const isShowPrice = [0, 2].includes(status.code) && price.tag?.id
  const isShowBooking = [0].includes(status.code) && (booking.afisha.active || booking.tomesto.active)

  let bookingInfoClass = null
  if (booking?.afisha?.active) {
    bookingInfoClass = 'booking-afisha'
  } else if (booking?.tomesto?.active) {
    bookingInfoClass = 'booking-tomesto'
  }

  const MainContent = () => (
    <div className={s.main}>
      {isShowChevron && <ChevronWithTooltip icon={IconChevron} className={s.chevron} />}

      {isShowRating && (
        <div className={s['rating-wrapper']}>
          {feedback.rating >= 4 && <Rating rating={feedback.rating} />}

          {countReviews > 0 && (
            <div className={s['rating-right']}>
              <Text tag='span' sizes='S' onClick={() => scrollToElem('reviews')} className={s['rating-count']}>
                {countReviewsText}
              </Text>
            </div>
          )}
        </div>
      )}

      <div className={s['content-wrapper']}>
        <div className={s['rest-additional-info-wrap']}>
          {type && (
            <Text tag='span' className={s['rest-tag']} uppercase sizes='S'>
              {type}
            </Text>
          )}

          {isShowPrice && (
            <>
              <span className={s['price-dot']}></span>

              <PriceRange
                priceRange={price.level}
                colorMode={isMobile || !status.open || !isShowImg ? 'black' : 'white'}
                link={price.tag.url}
                onClick={() => router.push(price.tag.url, price.tag.url, { scroll: false })}
              />
            </>
          )}
        </div>

        <h1 className={s['rest-title']}>
          {name && (
            // @ts-ignore
            <Title tag='span' sizes='h1 h2 h3' className={s['rest-title-name']}>
              {name}
            </Title>
          )}

          {fullAddress && (
            <Text tag='span' sizes='M S' className={s['rest-title-address']}>
              {fullAddress}
            </Text>
          )}
        </h1>

        {status.open && status.code === 0 ? (
          <RestaurantCoverSchedule />
        ) : (
          <>
            <Text
              sizes={'M'}
              className={s['rest-status']}
              data-is-img={isShowImg}
              data-opening-soon={status.openingSoon}>
              {status.name}
            </Text>

            {getSimilarRestsLink && (
              <Button
                sizes='XL'
                mode={'black'}
                className={s['rest-similar-button']}
                onClick={() => router.push(getSimilarRestsLink, getSimilarRestsLink, { scroll: false })}>
                Найти похожее
              </Button>
            )}
          </>
        )}

        {isShowBooking && (
          <div className={s['main-buttons-wrapper']}>
            <Button
              sizes='XL'
              mode={isMobile ? 'black' : 'white'}
              className={`${s['rest-booking-button']} ${bookingInfoClass}`}
              onClick={handleBooking}>
              Забронировать столик
            </Button>
            {discounts.remarked && (
              <PromoBadgeCTA sizes={'XL'} className={s['promo-badge']}>
                -{discounts.remarked}₽
              </PromoBadgeCTA>
            )}
          </div>
        )}
      </div>
    </div>
  )

  const AsideContent = () => (
    <div className={s.aside}>
      <div className={s['aside-top']}>
        {isShowRating && (
          <div className={s['rating-wrapper']}>
            {feedback.rating >= 4 && <Rating sizes='S' rating={feedback.rating} />}

            {countReviews > 0 && (
              <div className={s['rating-right']}>
                <Text tag='span' sizes='S' onClick={() => scrollToElem('reviews')}>
                  {countReviewsText}
                </Text>
              </div>
            )}
          </div>
        )}
      </div>

      {isShowChevron && <ChevronWithTooltip icon={IconChevron} className={s.chevron} />}

      <div className={s['aside-bottom']}>
        <div className={s.partners}>
          {/*TODO: Await data from backend*/}
          {/*<RestaurantPartner partner={'Michelin'} countStars={1} />*/}
          {/*<RestaurantPartner partner={'Gault & Millau'} countStars={1} />*/}
        </div>

        {isShowActionButtons && (
          <div className={s['rest-actions-wrap']}>
            {isShowLikeButton && (
              <IconButton icon={IconComment} sizes='XL M' mode='transparent' onClick={handleClickScrollToReviews} />
            )}
            {isShowReviewsButton && (
              <Favorite
                category={'restaurant'}
                id={id}
                analytic={{
                  data: stateRestaurant.data as TypeMappingRestOutput,
                  other: { section_name: 'page' },
                }}>
                <IconButton icon={IconLike} sizes='XL M' mode='transparent' />
              </Favorite>
            )}
          </div>
        )}
      </div>
    </div>
  )

  const imgAlt = name ? `Фото ресторана от Афиши — ${name}` : `Фото ресторана от Афиши`
  const getImg = useMemo(() => {
    if (!isShowImg || !img) return getPlaceholderImage()
    else return img
  }, [img])

  return (
    <ErrorBoundary>
      {isShowImg || isMobile ? (
        <Cover img={getImg} classNameContainer={s.container}>
          <Cover.Main alt={imgAlt}>
            <MainContent />
          </Cover.Main>

          <Cover.Aside alt={imgAlt} className={`${!isShowImg ? s['aside-without-bg'] : ''}`}>
            <AsideContent />
          </Cover.Aside>
        </Cover>
      ) : (
        <ContainerLong classNameContent={s['container-without-bg']}>
          <MainContent />
          <AsideContent />
        </ContainerLong>
      )}
    </ErrorBoundary>
  )
}

export default RestaurantCoverWrapper
