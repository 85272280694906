import React, { FC, ReactNode, useContext } from 'react'
import s from './SectionCarouselItems.module.sass'
import { Carousel } from '@shared/ui/Layout/Carousel'
import { SectionCarouselContext } from '@shared/ui/Layout/SectionCarousel/SectionCarouselContext'
import { KeenSliderOptions } from 'keen-slider/react'

interface Props {
  children: ReactNode[]
  onSlideChanged?: (data: KeenSliderOptions) => void
}

const SectionCarouselItems: FC<Props> = ({ children, onSlideChanged }) => {
  const [context, setContext] = useContext(SectionCarouselContext)

  const handleSlideChanged = (data: KeenSliderOptions) => {
    onSlideChanged && onSlideChanged(data)
  }

  if (!context.skeleton) {
    return (
      <Carousel.Slider
        // @ts-ignore
        countSlidesOnContainer={context.countSlidesOnContainer}
        className={s.slider}
        style={{ borderRadius: context.borderRadius }}
        slideChanged={handleSlideChanged}>
        {children}
      </Carousel.Slider>
    )
  }
}

export default SectionCarouselItems
