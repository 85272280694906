import s from './OwnerEditPostForm.module.sass'

import React, { FC, memo } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { closeForm } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/reducer'
import { OwnerEditPostFormSpecial } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/OwnerEditPostForm/ui/SubformSpecial'
import { OwnerEditPostFormNews } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/OwnerEditPostForm/ui/SubformNews'
import { OwnerEditPostFormMenu } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/OwnerEditPostForm/ui/SubformMenu'

import { Modal } from '@shared/ui/Overlays/Modal'
import { Text } from '@shared/ui/Typography/Text'
import { Button } from '@shared/ui/Actions/Button'
import { postCreate } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/createActions'
import { postDelete } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/createAsyncThunk'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  className?: string
}

const mappingTypeCategories = {
  special: 'спецпредложение',
  news: 'новость',
  menu: 'обновление меню',
}

const OwnerCreatePostFormContainer: FC<Props> = (props) => {
  const stateCreatePost = useAppSelector((store) => store?.pages?.owner_personal?.posts?.forms?.edit)

  return stateCreatePost?.opened && <OwnerEditPostForm {...props} />
}

const OwnerEditPostForm: FC<Props> = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const statePosts = useAppSelector((store) => store?.pages?.owner_personal?.posts)
  const stateCreatePost = statePosts.forms?.edit
  const { publicDate, modifiedDate, selectedCategory, categories } = stateCreatePost

  const handleCloseForm = () => {
    dispatch(closeForm())
  }

  return (
    <ErrorBoundary>
      <Modal
        title={`Редактировать ${mappingTypeCategories[selectedCategory]}`}
        subtitle={
          <div className={s.subtitle}>
            {publicDate ? (
              <Text className={s['public-date']} sizes={'M'}>
                Опубликовано {new Date(publicDate).toLocaleDateString('ru-RU')}
              </Text>
            ) : (
              <Text className={s['save-date']} sizes={'M'}>
                Не опубликовано. Последнее сохранение {new Date(modifiedDate).toLocaleDateString('ru-RU')}
                <br />
                Публикуется 1 раз в сутки.
              </Text>
            )}
          </div>
        }
        size={'S'}
        handleClose={handleCloseForm}
        className={s['owner-edit-form']}>
        <>
          <div className={s.content}>
            {selectedCategory === 'special' && <OwnerEditPostFormSpecial />}
            {selectedCategory === 'news' && <OwnerEditPostFormNews />}
            {selectedCategory === 'menu' && <OwnerEditPostFormMenu />}
          </div>

          <div className={s.footer}>
            {!statePosts.canPublished && (
              <Text sizes={'S'} className={s['footer-warning']}>
                Взаимодействовать с объявлением можно не чаще раза в минуту
              </Text>
            )}
            <div className={s['data-submit-wrap']}>
              {publicDate && (
                <Button
                  // @ts-ignore
                  onClick={(ev) => dispatch(postDelete())}
                  className={s['data-submit-btn-secondary']}
                  sizes='XL'
                  mode='white-border'
                  disabled={!statePosts.canPublished}>
                  Снять с публикации
                </Button>
              )}

              <Button
                onClick={(ev) => dispatch(postCreate())}
                className={s['data-submit-btn-primary']}
                sizes='XL'
                mode='black'
                disabled={!statePosts.canPublished}>
                Опубликовать
              </Button>
            </div>
          </div>
        </>
      </Modal>
    </ErrorBoundary>
  )
}

export default memo(OwnerCreatePostFormContainer)
