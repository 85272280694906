import s from './SubformNews.module.sass'

import React, { FC, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { getField } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/lib/fields'
import { OwnerCalendar } from '@pages/OwnerPersonalPage/ui/components/OwnerCalendar'
import { setFieldValid, setFieldValue } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/reducer'

import { ImageCropping } from '@widgets/Common/ImageCropping'

import { BuildInput } from '@shared/ui/Form/Build/BuildInput'
import { BuildTextarea } from '@shared/ui/Form/Build/BuildTextarea'
import { FieldBase, FieldInput, FieldTextarea } from '@shared/types/fields/fields'
import { InputTitle } from '@shared/ui/Form/Single/InputTitle'
import { Text } from '@shared/ui/Typography/Text'
import { useField } from '@shared/lib/fields/hooks/useField/useField'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

const SubformNews: FC = () => {
  const dispatch = useAppDispatch()
  const editForm = useAppSelector((store) => store?.pages?.owner_personal?.posts?.forms?.edit)
  const {
    date: dateStore,
    title: titleStore,
    description: descriptionStore,
    preview: previewStore,
  } = editForm.categories.news.fields

  const [fieldSelectedDate, setFieldSelectedDate] = useState(null)
  const [fieldTitle, setFieldTitle] = useField(getField('news_title'))
  const [fieldDescription, setFieldDescription] = useField(getField('news_description'))
  const [fieldPhoto, setFieldPhoto] = useState(previewStore.value)

  // установка данных полей из store при первом\повторных отображений компонента(value и valid)
  useEffect(() => {
    setFieldSelectedDate(() => (dateStore.value ? new Date(dateStore.value) : null))
    setFieldTitle((prev) => ({ ...prev, ...titleStore }))
    setFieldDescription((prev) => ({ ...prev, ...descriptionStore }))
  }, [])

  useEffect(() => {
    // @ts-ignore
    const isValidDate = (d: any) => d instanceof Date && !isNaN(d)

    dispatch(
      setFieldValue({ typeField: 'date', value: isValidDate(fieldSelectedDate) ? fieldSelectedDate.toJSON() : null }),
    )
    dispatch(setFieldValue({ typeField: 'title', value: fieldTitle.value }))
    dispatch(setFieldValue({ typeField: 'description', value: fieldDescription.value }))
    dispatch(setFieldValue({ typeField: 'preview', value: fieldPhoto }))

    // dispatch(setFieldValid({ typeField: 'date', valid: isValidDate(fieldSelectedDate) }))
    dispatch(setFieldValid({ typeField: 'date', valid: true }))
    dispatch(setFieldValid({ typeField: 'title', valid: fieldTitle.valid }))
    dispatch(setFieldValid({ typeField: 'description', valid: fieldDescription.valid }))
    dispatch(setFieldValid({ typeField: 'preview', valid: !!fieldPhoto }))
  }, [fieldTitle, fieldDescription, fieldPhoto])

  return (
    <ErrorBoundary>
      <div className={s.wrapper}>
        {/*{!editForm.publicDate && (*/}
        {/*  <div>*/}
        {/*    <InputTitle nameField={'owner-date-spec'} title={'Дата публикации'} showRequired={false} />*/}
        {/*    <OwnerCalendar selectedDate={fieldSelectedDate} onChangeDate={setFieldSelectedDate} />*/}
        {/*  </div>*/}
        {/*)}*/}

        <BuildInput
          field={fieldTitle as FieldInput}
          setField={setFieldTitle as React.Dispatch<React.SetStateAction<FieldBase>>}
        />

        <BuildTextarea
          field={fieldDescription as FieldTextarea}
          setField={setFieldDescription as React.Dispatch<React.SetStateAction<FieldBase>>}
        />

        <div>
          <InputTitle nameField={'owner-date-spec'} title={'Обложка'} showRequired={false} />
          <ImageCropping handleSaveImage={setFieldPhoto} img={fieldPhoto} />
          {!fieldPhoto && (
            <Text className={s['photo-description']} sizes='S'>
              Пожалуйста, загружайте фотографии, права на которые принадлежат вам или допускают свободное использование
              в коммерческих целях. Принимаются только jpg и jpeg форматы. Фотографии будут добавлены после модерации.
            </Text>
          )}
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default SubformNews
