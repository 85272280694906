import { API } from '@shared/api'
import parseCookie from '@shared/lib/helpers/parseCookie.js'

const GITLAB_STATUS = process.env.GITLAB_STATUS || null
const IS_PROD = GITLAB_STATUS === '0'

type callback = {
  (param?: any): void
}

type TypeListenersKey =
  | 'openAuth'
  | 'loginStart'
  | 'loginSuccess'
  | 'loginError'
  | 'logoutStart'
  | 'logoutSuccess'
  | 'logoutError'

type TypeListeners = {
  openAuth: callback[]
  loginStart: callback[]
  loginSuccess: callback[]
  loginError: callback[]
  logoutStart: callback[]
  logoutSuccess: callback[]
  logoutError: callback[]
}

declare global {
  interface Window {
    ramblerIdHelper: any
  }
}

class Authorization {
  static isInit = false

  /**
   * @static listeners
   * @description
   */
  static listeners: TypeListeners = {
    openAuth: [],
    loginStart: [],
    loginSuccess: [],
    loginError: [],
    logoutStart: [],
    logoutSuccess: [],
    logoutError: [],
  }

  /**
   * @static init
   * @description Подиска на события Rambler ID
   */
  static init = () => {
    !IS_PROD &&
      Object.keys(Authorization.listeners).forEach((listenerName) => {
        Authorization.listeners[listenerName].push((data) => console.log(listenerName, data))
      })

    Authorization.isInit = true

    // Авторизация стандартным способом
    AuthorizationRamblerId.onLogin(Authorization.login)

    // Авторизация черец соцсети
    AuthorizationRamblerId.onOauthLogin(Authorization.login)
  }

  /**
   * @static _callListeners
   * @description
   * @param name
   * @param data
   */
  static _callListeners = (name: TypeListenersKey, data = null) => {
    Authorization.listeners[name].forEach((callback) => callback(data))
  }

  /**
   * @static addListener
   * @description
   * @param name
   * @param callback
   */
  static addListener = (name: TypeListenersKey, callback) => {
    const hasEvent = Object.keys(Authorization.listeners).includes(name)
    const listenerArray = Authorization.listeners[name]

    if (!hasEvent) {
      throw new Error(`События ${name} не существует в классе Authorization`)
    }

    if (listenerArray.length >= 10) {
      throw new Error(`Превышено количество коллбеков на событие ${name} в классе Authorization. Max = 10`)
    }

    // Проверяем есть ли в массиве такой метод, для избежания дублирования
    const checkThisMethodInArray = listenerArray.findIndex((oldCallback) => oldCallback === callback)

    if (checkThisMethodInArray === -1) {
      listenerArray.push(callback)
    }
  }

  /**
   * @static removeListener
   * @description
   * @param name
   * @param callback
   */
  static removeListener = (name: TypeListenersKey, callback) => {
    const hasEvent = Object.keys(Authorization.listeners).includes(name)
    if (!hasEvent) throw new Error(`События ${name} не существует в классе Authorization`)

    const listenerArray = Authorization.listeners[name]

    const findIndexRemoveCallback = listenerArray.findIndex((oldCallback) => oldCallback === callback)
    if (findIndexRemoveCallback === -1) return

    listenerArray.splice(findIndexRemoveCallback, 1)
  }

  /**
   * @static openAuth
   * @description
   */
  static openAuth = () => {
    AuthorizationRamblerId.openAuth()
    Authorization._callListeners('openAuth')
  }

  /**
   * @static isAuthorized
   * @description Проверка авторизован ли пользователь в Rambler ID
   *    Если авторизован то возвращает токен для AuthorizationRests.login
   */
  static isAuthorized = () =>
    new Promise((resolve, reject) => {
      AuthorizationRamblerId.getSessionToken(async (response) => {
        response?.token ? resolve(response.token) : reject(response)
      })
    })

  /**
   * @static login
   * @description Получить данные пользователя
   *    Данные возращаются только когда пользователь авторизован в Rambler ID
   */
  static login = () => {
    Authorization._callListeners('loginStart')

    Authorization.isAuthorized()
      .then(async (token) => {
        const resultLogin = await AuthorizationRests.login(token)
        const userData = resultLogin ? await API.user_me({ repeated: true }) : null
        if (!userData) throw new Error('Не удалось получить данные пользователя')

        AuthorizationRamblerId.closeAuth()

        Authorization._callListeners('loginSuccess', userData)
        await AuthorizationAfisha.login(token)
      })
      .catch((error) => {
        Authorization._callListeners('loginError', error)
      })
  }

  /**
   * @static logout
   * @description
   */
  static logout = async () => {
    Authorization._callListeners('logoutStart')

    const callback = async () => {
      const status = await AuthorizationRests.logout()
      Authorization._callListeners(status ? 'logoutSuccess' : 'logoutError')
      AuthorizationRamblerId.removeLogout(callback)
    }

    await AuthorizationAfisha.logout()
    AuthorizationRamblerId.onLogout(callback)
    AuthorizationRamblerId.logout()

    window.location.reload()
  }
}

class AuthorizationRests {
  /**
   * @static login
   * @description
   * @param sessionToken
   * @returns true | false
   */
  static login = async (sessionToken) => {
    if (!sessionToken) return false

    const response = await fetch('https://www.afisha.ru/rests/auth/register/rambler-id/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      credentials: 'include',
      body: new URLSearchParams({ SessionID: sessionToken }),
    })

    switch (response.status) {
      case 200: {
        return true
      }
      case 403: {
        console.error('Error 403: The user is most likely blocked')
        return false
      }
      default: {
        console.error('Unknown error > response ', response)
        return false
      }
    }
  }

  /**
   * @static logout
   * @description
   * @returns true | false
   */
  static logout = async () => {
    const reqToken = parseCookie('csrftoken')
    if (!reqToken) return false

    try {
      await fetch('https://www.afisha.ru/rests/auth/unregistercurrentsession/', {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-CSRFToken': reqToken,
        },
      })
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }
}

class AuthorizationAfisha {
  /**
   * @static login
   * @description Данный логин нужен чтобы корректно работали методы API большой Афиши
   *    (В частности получение куки _afishaidJWT)
   * @description Авторизация для большой Афиши должна быть в конце всей цепочки запросов
   * @param rsid - Для запроса требуется rsidx из запроса getRsidx
   * @returns true | false
   */
  static login = async (rsid) => {
    const response = await fetch('https://www.afisha.ru/api/auth/login/', {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({ Rsid: rsid }),
    })

    switch (response.status) {
      case 200: {
        return true
      }
      default: {
        console.error('Error request https://www.afisha.ru/api/auth/login/')
        return false
      }
    }
  }

  /**
   * @static logout
   * @description
   * @returns true | false
   */
  static logout = async () => {
    const response = await fetch('https://www.afisha.ru/api/auth/logout/', {
      method: 'POST',
      headers: { Accept: 'application/json' },
    })

    switch (response.status) {
      case 200: {
        return true
      }
      default: {
        console.error('Error request https://www.afisha.ru/api/auth/logout/')
        return false
      }
    }
  }
}

class AuthorizationRamblerId {
  /**
   * @static openAuth
   * @description
   */
  static openAuth = (options?: Record<string, unknown>) => {
    window.ramblerIdHelper?.openAuth({
      rname: 'afisha',
      theme: 'afisha',
      path: '/login-20/phone-login',
      back: 'https://www.afisha.ru/msk/restaurants/',
      ...options,
    })
  }

  /**
   * @static closeAuth
   * @description
   */
  static closeAuth = () => {
    window.ramblerIdHelper?.closeAuth()
  }

  /**
   * @static logout
   * @description
   */
  static logout = () => {
    window.ramblerIdHelper?.logout()
  }

  /**
   * @static getSessionToken
   * @description
   */
  static getSessionToken = (callback: (data: { ctime: number; token: string; ttl: number } | null) => void) => {
    window.ramblerIdHelper?.getSessionToken(callback)
  }

  /**
   * @static onLogin
   * @description
   * @param callback
   */
  static onLogin = (callback) => {
    window.ramblerIdHelper?.addListener('login', callback)
  }

  /**
   * @static onOauthLogin
   * @description
   * @param callback
   */
  static onOauthLogin = (callback) => {
    window.ramblerIdHelper?.addListener('oauthlogin', callback)
  }

  /**
   * @static onLogout
   * @description
   * @param callback
   */
  static onLogout = (callback) => {
    window.ramblerIdHelper?.addListener('logout', callback)
  }

  /**
   * @static removeLogout
   * @description
   * @param callback
   */
  static removeLogout = (callback) => {
    window.ramblerIdHelper?.removeListener('logout', callback)
  }
}

export default Authorization
