import React, { forwardRef, HTMLProps } from 'react'
import s from './PopupContent.module.sass'
import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  useMergeRefs,
  useTransitionStatus,
} from '@floating-ui/react'
import { usePopupContext } from '@shared/ui/Overlays/Popup/hooks'

export const PopupContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ style, className = '', ...props }, propRef) => {
    const { context: floatingContext, arrowRef, visibleArrow, ...context } = usePopupContext()
    const ref = useMergeRefs([context.refs.setFloating, propRef])

    // задержка для переходов стилей CSS
    const { isMounted, status } = useTransitionStatus(floatingContext, { duration: 200 })

    if (!floatingContext.open && !isMounted) return null

    return (
      <FloatingPortal id='next-content'>
        <FloatingFocusManager context={floatingContext} modal={true}>
          <div
            ref={ref}
            data-status={status}
            className={s['popup-wrapper']}
            style={context.floatingStyles}
            {...context.getFloatingProps(props)}>
            <div className={`${s.popup} ${className}`} data-status={status} style={style}>
              {visibleArrow && (
                <FloatingArrow
                  ref={arrowRef}
                  context={floatingContext}
                  width={32}
                  className={s.arrow}
                  d='M0.0429688 32.0003H31.9628C24.5379 31.9701 18.4054 27.2948 17.3854 21.2197C17.3237 20.8522 17.1378 20.5138 16.8427 20.2862V20.2862C16.3478 19.9047 15.6578 19.9045 15.1629 20.2861V20.2861C14.8679 20.5136 14.6821 20.8519 14.6204 21.2193C13.6007 27.2946 7.46808 31.9701 0.0429688 32.0003Z'
                />
              )}
              {props.children}
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  },
)

export default PopupContent
