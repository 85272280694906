import React, { FC } from 'react'
import s from './QuickBookingRestShortC.module.sass'
import { useAppSelector } from '@app/model/store'
import { QuickBookingRestCSkeleton } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestC/index'
import { QuickBookingSlots } from '@widgets/Restaurant/QuickBooking/components/QuickBookingSlots'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { TypeDateTimeSlot } from '@shared/types/schedule/types'
import { EGG } from '@shared/api/analytics'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  slotsData: {
    slots: TypeDateTimeSlot[]
    loading: boolean
    error: boolean
  }
  className?: string
}

const QuickBookingRestShortCWrapper: FC<Props> = ({ slotsData, className = '' }) => {
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item.data)
  const hasBookingAfisha = stateRestaurant.booking.afisha.active
  if (!hasBookingAfisha) return

  return stateRestaurant ? (
    <QuickBookingRestShortC slotsData={slotsData} className={className} />
  ) : (
    <QuickBookingRestCSkeleton />
  )
}

const QuickBookingRestShortC: FC<Props> = ({ slotsData, className = '' }) => {
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item.data)

  const handleClickSlot = (data) => {
    EGG.entity.booking.slots_quick_booking_slot_click(data, null)
  }
  const handleClickDots = (data) => {
    EGG.entity.booking.slots_quick_booking_dots_click(data, null)
  }

  return (
    <ErrorBoundary>
      <QuickBookingSlots
        slotsData={slotsData}
        restData={stateRestaurant as TypeMappingRestOutput}
        classNameWrapper={`${s.schedule} ${className}`}
        classNameSlot={s.slot}
        classNameDots={s.dots}
        onClickSlot={handleClickSlot}
        onClickDots={handleClickDots}
      />
    </ErrorBoundary>
  )
}

export default QuickBookingRestShortCWrapper
