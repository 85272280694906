import asyncEach, {Callback, Worker} from 'async-each'
import * as cookies from '@rambler-id/cookies'
import * as chainId from '@rambler-id/chain-id'
import {Scope} from '@sentry/browser'
import {getItem} from '@rambler-id/local-storage'
import {hasTrackingPrevention, getItpUrls} from '@rambler-id/itp'
import {ApiError} from '@rambler-id/errors'
import {androidWebView, iosWebView} from '@rambler-id/browser'
import {addUrlParams, getUrlParams} from '@rambler-id/url'
import * as sessionStorage from '@rambler-id/session-storage'
import {get} from '../api/get'
import * as Id from '../api/id'
import {Events} from '../utils/events'
import {loadSentry} from '../utils/sentry'
import {
  IS_REDIRECT_AUTH_PENDING_KEY,
  SENTRY_IGNORE_ID_ERRORS,
  TEST_NAMESPACE
} from '../utils/constants'
import {
  HelperCallback,
  BaseAuthWindowOptions,
  AuthWindowOptions,
  OAuthWindowOptions,
  PropagateSessionExtra,
  SberIdNotificationBannerOptions,
  HelperEventMeta
} from '../types'
import * as RamblerId from './rambler-id'
import * as SberId from './sber-id'
import {SharedEmitter} from './shared-emitter'
import {createDebug, activateDebug} from '@rambler-id/debug'
import {randomString} from '../utils/string'

export interface ProfileInfo {
  display: {
    avatar: {
      url: string
      provider: string
      provider_icon: string
    }
    short_name: string
    display_name: string
  }
  __type: string
}

export interface UserInfo {
  provider: string
  iconUrl: string
  avatarUrl: string
  shortName: string
  displayName: string
  mailboxExists: number
}

export interface ResponseQRCode {
  session_copy_request: string
  session_copy_token: string
}

const noop = (): void => undefined

const getDebug = (options: BaseAuthWindowOptions): Record<string, any> => {
  const debug = options.debug ?? getUrlParams(window.location.href).debug

  return debug ? {debug} : {}
}

const REDIRECTOR_PREFIX =
  getItem<number>('itpRedirectorStageApi') === 1
    ? '/api/itp/v2/test-redirector'
    : '/api/itp/v2/redirector'

const CDN_ORIGIN = process.env.CDN_ORIGIN
const CDN_PREFIX = process.env.CDN_PREFIX
const API_ORIGIN = process.env.API_ORIGIN
const VERSION = process.env.VERSION

const debug = createDebug('id:helper')

activateDebug()
debug(VERSION)

/**
 * JavaScript-API для Rambler/ID
 */
export class RamblerIdHelper extends SharedEmitter {
  /**
   * Интерфейс ramblerIdHelper предоставляет методы интерфейса EventEmitter
   * так что вы можете слушать события с помощью JavaScript API
   *
   * ```js
   * ramblerIdHelper.addListener(ramblerIdHelper.Events.LOGIN, () => {
   *   // пользователь залогинился
   * })
   * ```
   */
  public Events = Events

  /**
   * Проверить, есть ли в браузере Intelligent Tracking Prevention
   */
  public get hasTrackingPrevention(): boolean {
    return hasTrackingPrevention
  }

  private userSession = false
  private authFrame?: RamblerId.AuthWidget
  private sberIdBanner?: SberId.NotificationBanner

  public constructor() {
    super({storage: true})
    this.addListener(Events.LOGIN, this.removeCache)
    this.addListener(Events.OAUTHLOGIN, this.removeCache)
    this.addListener(Events.LOGOUT, this.removeCache)
    this.addListener(Events.ERROR, this.sendError)
    this.addListener(Events.REQUEST_QR_CODE, this.requestQRCode)
  }

  /**
   * Позволяет инициализировать ramblerIdHelper в любое время,
   * например, перед асинхронной загрузкой скрипта
   *
   * ```html
   * <script>
   *   window.ramblerIdHelper = window.ramblerIdHelper || []
   *   window.ramblerIdHelper.push(() => {
   *     // ramblerIdHelper загружен
   *   })
   * </script>
   * <script async src="//id.rambler.ru/rambler-id-helper/auth_events.js"></script>
   * ```
   */
  public push(callback: HelperCallback = noop): void {
    callback()
  }

  // NOTE а в конструкторе хелпера уже слушаться requestQr,
  // дергаться апи и эмититься responseQr
  private requestQRCode = (
    callback: (data: {
      response: ResponseQRCode | null
      error?: ApiError | Error | null
    }) => void | Record<string, any>,
    meta?: HelperEventMeta
  ): void => {
    const callFromStandalone = typeof callback === 'function'
    const callFromIframe = !!meta?.source

    if (callFromStandalone || callFromIframe) {
      Id.requestQRCode(
        {domain: window.location.host.replace('www.', '')},
        (error: ApiError | Error | null, response: ResponseQRCode) => {
          if (error) {
            this.emit(Events.ERROR, error)
          }

          if (callFromIframe) {
            this.emit(
              Events.RESPONSE_QR_CODE,
              // NOTE спредим ошибку, потому что она не сериализуемая в postMessage
              {response, error: error ? {...error} : null},
              {
                target: meta.source
              }
            )
          } else {
            callback({response, error})
          }
        }
      )
    }
  }

  /**
   * Get tokens for qr code auth
   *
   * @param callback Callback returns private and public token for qr code auth
   * @internal
   */
  public getAuthQRCode = (
    callback: (
      response: ResponseQRCode | null,
      error?: ApiError | Error | null
    ) => void,
    widgetType: AuthWindowOptions['param']
  ): void => {
    const listenQR = (data: {
      response: ResponseQRCode | null
      error?: ApiError | Error | null
    }): void => {
      const {error, response} = data

      if (error) {
        callback(null, new ApiError(error))
      } else {
        callback(response)
      }

      this.removeListener(Events.RESPONSE_QR_CODE, listenQR)
    }

    // NOTE Если standalone сразу запросим токен
    if (!widgetType) {
      this.requestQRCode(listenQR)
    } else {
      //NOTE  Из этого эмита мы попадем в onMessage
      this.emit(Events.REQUEST_QR_CODE)
      this.addListener(Events.RESPONSE_QR_CODE, listenQR)
    }
  }

  private sendError = (error: Error | ApiError): void => {
    const ignoreError =
      ~SENTRY_IGNORE_ID_ERRORS.indexOf(error.message) ||
      ('code' in error &&
        error.code &&
        ~SENTRY_IGNORE_ID_ERRORS.indexOf(error.code))

    if (ignoreError) {
      return
    }

    loadSentry((Sentry) => {
      Sentry.withScope((scope: Scope) => {
        const exceptionExtra: Record<string, any> = {
          ...('code' in error && {code: error.code}),
          ...('details' in error && error.details)
        }

        for (const key in exceptionExtra) {
          if (exceptionExtra.hasOwnProperty(key)) {
            scope.setExtra(key, exceptionExtra[key])
          }
        }

        Sentry.captureException(error)
      })
    })
  }

  private removeCache = (): void => {
    chainId.removeCache()
  }

  /**
   * Уничтожить текущую сессию
   *
   * @param params Параметры переданные в событие `logout`
   * @param callback Коллбек, который выполняется после уничтожения сессии
   */
  public logout(
    params?: HelperCallback | any,
    callback: HelperCallback = noop
  ): void {
    if (typeof params === 'function') {
      callback = params
      params = undefined
    }

    Id.destroySession({}, (error) => {
      if (error) {
        this.emit(Events.ERROR, error)
      }

      this.emit(Events.LOGOUT, params)
      callback(null, error)
    })
  }

  /**
   * Получить текущую информацию о пользователе
   *
   * @param params Параметры для получения [подробной информации о пользователе](https://confluence.rambler-co.ru/pages/viewpage.action?pageId=22873594)
   * @param callback Коллбек, который возвращает объект информации о пользователе
   */
  getProfileInfo(
    params: HelperCallback | any,
    callback: HelperCallback = noop
  ): void {
    if (typeof params === 'function') {
      callback = params
      params = {}
    }

    Id.getProfileInfo(
      {...params, enc_avatar_url_nonce: randomString()},
      (error, response) => {
        this.userSession = !!response

        if (error) {
          this.emit(Events.ERROR, error)
        } else if (response && !this.isRamblerOrigin) {
          const isRedirectAuthPending = sessionStorage.getItem(
            IS_REDIRECT_AUTH_PENDING_KEY
          )

          if (isRedirectAuthPending) {
            this.emit(Events.LOGIN)
          }

          sessionStorage.removeItem(IS_REDIRECT_AUTH_PENDING_KEY)
        }

        callback(response ? response.profile : null, error)
      }
    )
  }

  /**
   * Получить токен сессии
   *
   * @param callback Коллбек, который возвращает токен текущей сессии
   */
  getSessionToken(callback: HelperCallback = noop): void {
    Id.getRsidx({}, (error, response) => {
      this.userSession = !!response

      if (error) {
        this.emit(Events.ERROR, error)
      }

      chainId.setCache(error ?? response?.result.rccid)
      callback(response ? response.result : null, error)
    })
  }

  /**
   * Получить chain id по умолчанию
   *
   * @param callback Коллбек, который возвращает chain id
   */
  getDefaultChainId(callback: HelperCallback = noop): void {
    const cache = chainId.getCache()

    if (cache instanceof Error) {
      callback(null, cache)

      return
    }

    if (cache) {
      callback({rccid: cache})

      return
    }

    Id.getRccid({}, (error, response) => {
      if (error) {
        this.emit(Events.ERROR, error)
      }

      chainId.setCache(error ?? response?.rccid)
      callback(response ? {rccid: response.rccid} : null, error)
    })
  }

  /**
   * Создать новое браузерное окно для проставления сессия с его помощью
   * Возвращается null в браузерах, в которых нет ITP
   */
  createPropagationWindow(): Window | null {
    if (!this.hasTrackingPrevention || iosWebView || androidWebView) {
      return null
    }

    return window.open(
      `${CDN_ORIGIN}${CDN_PREFIX}/${VERSION}/noop.html`,
      '',
      `status=1,toolbar=0,menubar=0,resizable=1,scrollbars=1,width=250px,height=100px,top=100px,left=100px`
    )
  }

  /**
   * Проставить сессию на все SSO-домены
   *
   * @param rsid Идентификатор сессии для проставления
   * @param options Дополнительные параметры
   * @param callback Необязательный коллбек перед редиректом
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  propagateSession(
    rsid: string,
    options: PropagateSessionExtra = {eventType: Events.LOGIN},
    callback = noop
  ): void {
    const {
      eventType = Events.LOGIN,
      shortSession = false,
      propagationWindow = null,
      rname,
      ...rest
    } = options

    if (rest.keepWindow) {
      rest.data = rest.data || {}
      rest.data.keepWindow = rest.keepWindow
    }

    if (!this.hasTrackingPrevention) {
      Id.getConfiguration({key: 'ServerSideDomains'}, (error, response) => {
        const serverSideItpDomains = error
          ? []
          : Object.keys(response.configuration).filter(
              (domain) => !!response.configuration[domain]
            )
        const worker: Worker<string, void> = (
          itpDomain: string,
          callback: Callback<void>
        ): void => {
          const domain = itpDomain.split('.').slice(-2).join('.')

          Id.setRsidLocal(
            {domain, id: rsid},
            () => callback(null),
            `https://${itpDomain}`
          )
        }

        asyncEach<string, void>(serverSideItpDomains, worker, () => {
          if (rest.back && !rest.popup) {
            const listener = (): void => {
              setTimeout(() => {
                this.removeListener(eventType, listener)
                callback()
                window.location.href = rest.back ?? ''
              }, 500)
            }

            this.addListener(eventType, listener)
          } else {
            callback()
          }

          this.emit(eventType, rest.data)
        })
      })

      return
    }

    const worker: Worker<string, [string, string, Error | null]> = (
      domain: string,
      callback: Callback<[string, string, Error | null]>
    ): void => {
      get(
        `https://${domain}/api/itp/v2/ping`,
        (error: Error | null, response: string) => {
          let data

          try {
            data = JSON.parse(response)
          } catch {}

          callback(null, [domain, data?.response, error])
        }
      )
    }

    const itpDomains = getItpUrls(rname ? [rname] : undefined)

    asyncEach<string, [string, string, Error | null]>(
      itpDomains,
      worker,
      (_, results) => {
        const [referenceResponse] = results
          .filter(([domain]) => domain === itpDomains[0])
          .map(([, response]) => response)
        const activeDomains: string[] = []
        const errors: Error[] = []

        results.forEach(([domain, response, error]) => {
          if (referenceResponse && referenceResponse === response) {
            activeDomains.push(domain)
          } else {
            errors.push(
              error ||
                new ApiError({
                  message: 'ping response wrong',
                  code: 0,
                  details: {
                    method: `https://${domain}/api/itp/v2/ping`
                  }
                })
            )
          }
        })

        if (errors.length > 0) {
          loadSentry(() => {
            errors.forEach((error) => this.emit(Events.ERROR, error))
          })
        }

        const [start = API_ORIGIN.replace(/^https?:\/\//, ''), ...domains] =
          activeDomains
        const backUrl =
          `${CDN_ORIGIN}${CDN_PREFIX}/${VERSION}/propagate.html` +
          `?extra=${encodeURIComponent(JSON.stringify({eventType, ...rest}))}`
        const propagateUrl =
          `https://${start}${REDIRECTOR_PREFIX}?rsid=${rsid || ''}` +
          `&setShortCookie=${shortSession ? 1 : 0}` +
          domains.map((domain) => `&to=${domain}`).join('') +
          `&back=${encodeURIComponent(backUrl)}`

        if (!propagationWindow) {
          callback()
          window.location.href = propagateUrl

          return
        }

        const listener = (): void => {
          this.removeListener(eventType, listener)
          callback()
        }

        this.addListener(eventType, listener)
        propagationWindow.location.replace(propagateUrl)
      }
    )
  }

  /**
   *
   * Активировать ID-куки в браузерах, которые требуют перехода к first-party омену для работы с куками
   *
   * @param callback Коллбек, который выполняется после активации кук
   * @internal
   */
  activateCookies(callback: HelperCallback = noop): void {
    if (window.parent === window) {
      callback()

      return
    }

    cookies.setItem(TEST_NAMESPACE, 1, {
      expires: 1 / 24 / 6,
      path: '/',
      domain: '.rambler.ru',
      samesite: 'none',
      secure: true
    })

    if (cookies.getItem(TEST_NAMESPACE)) {
      callback()

      return
    }

    const cookieWindow = window.open(
      `${CDN_ORIGIN}${CDN_PREFIX}/${VERSION}/cookies.html`,
      '',
      'status=1,toolbar=0,menubar=0,resizable=1,scrollbars=1,width=500px,height=300px,top=100px,left=100px'
    )
    const listener = (event: MessageEvent): void => {
      if (event.data === TEST_NAMESPACE) {
        window.removeEventListener('message', listener)
        cookieWindow?.close()
        callback()
      }
    }

    window.addEventListener('message', listener)
  }

  /**
   * Получить информацию о пользователе, авторизованном через Sber ID
   *
   * @param callback Коллбек, который возвращает объект информации о пользователе
   * @internal
   */
  public getSberIdUserInfo(callback: HelperCallback = noop): void {
    SberId.getUserInfo((error, result: SberId.UserInfo) => {
      callback(result, error)
    })
  }

  /**
   * Показать персонализированный баннер Sber ID авторизованному пользователю
   *
   * @param options Настройки баннера
   *
   * ```js
   * ramblerIdHelper.getProfileInfo((profile) => {
   *   if (!profile) {
   *     ramblerIdHelper.showSberIdNotificationBanner({position: 'bottom-right'})
   *   }
   * })
   * ```
   */
  public showSberIdNotificationBanner(
    options: SberIdNotificationBannerOptions & OAuthWindowOptions
  ): void {
    if (!SberId.isBannerClosed() && !this.sberIdBanner && !this.userSession) {
      this.getSberIdUserInfo((result) => {
        if (result && !this.sberIdBanner && !this.userSession) {
          const {
            position,
            fontFamily,
            offset,
            autoCloseDuration,
            ...oauthOptions
          } = options
          const displayName = `${result?.firstname || ''} ${
            result?.surname || ''
          }`.trim()

          this.sberIdBanner = new SberId.NotificationBanner({
            position,
            fontFamily,
            offset,
            autoCloseDuration,
            displayName
          })
          this.sberIdBanner.attach()

          const click = (): void => {
            this.openProviderOAuth({
              ...oauthOptions,
              action: 'login',
              provider: 'sberbank',
              provider_sso: Boolean(displayName)
            })
          }
          const destroy = (): void => {
            this.sberIdBanner?.destroy()
          }
          const cleanup = (): void => {
            this.sberIdBanner?.removeListener(SberId.Events.CLICK, click)
            this.sberIdBanner?.removeListener(SberId.Events.DESTROY, cleanup)
            this.removeListener(Events.LOGIN, destroy)
            this.removeListener(Events.OAUTHLOGIN, destroy)
            delete this.sberIdBanner
          }

          this.sberIdBanner.addListener(SberId.Events.CLICK, click)
          this.sberIdBanner.addListener(SberId.Events.DESTROY, cleanup)
          this.addListener(Events.LOGIN, destroy)
          this.addListener(Events.OAUTHLOGIN, destroy)
        }
      })
    }
  }

  /**
   * Совершить редирект на форму авторизации Rambler ID
   *
   * @param options Настройки формы авторизации Rambler ID
   */
  public redirectToAuth(
    options: AuthWindowOptions = {} as AuthWindowOptions
  ): void {
    debug('redirectToAuth %o', options)

    const {path = '/login-20/login', ...authOptions} = options

    const searchParams: AuthWindowOptions = {
      back: window.location.href,
      ...authOptions,
      ...getDebug(options)
    }

    const hashParams = {
      startTime: Date.now()
    }

    if (!this.userSession) {
      searchParams.session = false
    }

    const pageUrl = addUrlParams(
      `https://id.rambler.ru${path}`,
      searchParams,
      hashParams
    )

    if (!this.isRamblerOrigin) {
      sessionStorage.setItem(IS_REDIRECT_AUTH_PENDING_KEY, true)
    }

    window.location.href = pageUrl
  }

  /**
   * Открыть форму авторизации Rambler ID
   *
   * @param {Object} options Настройки формы авторизации Rambler/ID
   * @param {Function} callback Коллбек, который выолпняется после открытия формы
   */
  public openAuth(
    options: AuthWindowOptions = {} as AuthWindowOptions,
    callback: HelperCallback = noop
  ): void {
    debug('openAuth %o', options)

    const params: AuthWindowOptions = {
      ...options,
      ...getDebug(options),
      startTime: Date.now()
    }

    if (!this.userSession) {
      params.session = false
    }

    if (!this.authFrame) {
      this.authFrame = new RamblerId.AuthWidget(params)

      const destroy = (event: {on?: string; keepWindow?: boolean}): void => {
        const keepWindow =
          typeof event === 'object' &&
          (event?.keepWindow || event?.on === 'registration')

        if (!keepWindow) {
          this.authFrame?.destroy()
        }
      }
      const close = (...args: any[]): void => {
        this.emit(Events.CLOSE, ...args)
      }
      const cleanup = (): void => {
        // NOTE: timeout to remove listeners after all current listeners fired
        setTimeout(() => {
          this.authFrame?.removeListener(Events.CLOSE, close)
          this.authFrame?.removeListener(Events.DESTROY, cleanup)
          this.removeListener(Events.LOGIN, destroy)
          this.removeListener(Events.OAUTHLOGIN, destroy)
          delete this.authFrame
        }, 0)
      }

      this.authFrame.addListener(Events.CLOSE, close)
      this.authFrame.addListener(Events.DESTROY, cleanup)
      this.addListener(Events.LOGIN, destroy)
      this.addListener(Events.OAUTHLOGIN, destroy)
    } else {
      this.authFrame.configure(params)
    }

    const loaded = (): void => {
      this.authFrame?.removeListener(Events.LOADED, loaded)
      callback()
    }
    const cleanupLoaded = (): void => {
      // NOTE: timeout to remove listeners after all current listeners fired
      this.authFrame?.removeListener(Events.CLOSE, cleanupLoaded)
      this.authFrame?.removeListener(Events.DESTROY, cleanupLoaded)
      this.authFrame?.removeListener(Events.LOADED, loaded)
    }

    this.authFrame.addListener(Events.CLOSE, cleanupLoaded)
    this.authFrame.addListener(Events.DESTROY, cleanupLoaded)
    this.authFrame.addListener(Events.LOADED, loaded)
    this.authFrame.attach()
  }

  /**
   * Закрыть открытую форму авторизации ID
   *
   * @param callback Коллбек, который выполняется после закрытия
   */
  public closeAuth = (callback: HelperCallback = noop): void => {
    this.authFrame?.close()

    if (typeof callback === 'function') {
      callback()
    }
  }

  /**
   * Открыть вход через Рамблер ID с помощью OAuth-провайдера
   *
   * @param {Object} options Настройки Rambler ID и OAuth-провайдера
   */
  public openProviderOAuth(options: OAuthWindowOptions): void {
    debug('openProviderOAuth %o', options)

    const oAuthWidget = new RamblerId.OAuthWidget({
      ...options,
      ...getDebug(options),
      startTime: Date.now()
    })

    oAuthWidget.addListener(Events.OAUTHERROR, () => oAuthWidget.destroy())

    oAuthWidget.attach()
  }

  /**
   * Совершить редирект на вход через Рамблер ID с помощью OAuth-провайдера
   *
   * @param {Object} options Настройки Rambler ID OAuth
   */
  public redirectToProviderOAuth(options: OAuthWindowOptions): void {
    debug('redirectToProviderOAuth %o', options)

    const searchParams = {
      on: 'login',
      back: window.location.href,
      ...options,
      ...getDebug(options)
    }

    const hashParams = {
      startTime: Date.now()
    }

    const pageUrl = addUrlParams(
      'https://id.rambler.ru/oauth-20/',
      searchParams,
      hashParams
    )

    if (!this.isRamblerOrigin) {
      sessionStorage.setItem(IS_REDIRECT_AUTH_PENDING_KEY, true)
    }

    window.location.href = pageUrl
  }
}

export {Events}

export * from '../types'
