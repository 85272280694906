import React, { FC } from 'react'
import s from './FilterButtons.module.sass'
import { Button } from '@shared/ui/Actions/Button'
import { useAppSelector } from '@app/model/store'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  handleRemove: () => void
  handleSave: () => void
}

const FilterButtons: FC<Props> = ({ handleRemove, handleSave }) => {
  const { loading, restaurants, isPreloaderResults } = useAppSelector((store) => store.features.restaurant_filters)

  const inEnabled = !isPreloaderResults || restaurants.length

  const handleSaveInner = () => {
    !loading && inEnabled && handleSave()
  }

  return (
    <ErrorBoundary>
      <div className={s.buttons}>
        <Button className={s.remove} sizes={'L'} mode={'white-border'} onClick={handleRemove}>
          <Text sizes={'M'}>Сбросить</Text>
        </Button>
        <Button className={s.save} sizes={'L'} mode={'black'} onClick={handleSaveInner} data-loading={!!loading}>
          <Text sizes={'M'} className={s['save-text']}>
            {inEnabled ? 'Показать' : 'Не найдено'}
          </Text>
          <LoadingSpinner loading={loading} size={20} className={s['save-loading']} />
        </Button>
      </div>
    </ErrorBoundary>
  )
}

export default FilterButtons
