import s from './StatisticFromSource.module.sass'

import React, { FC } from 'react'

import { Text } from '@shared/ui/Typography/Text'

import IconClickH from '@icons/icon-click-h.svg'
// import IconYM from '@icons/icon-y-m.svg'

interface Props {
  className?: string
}

/**
 * @todo replace to /5_entities/ folder?
 */
const StatisticFromSource: FC<Props> = ({ className = '' }) => {
  return (
    <Text sizes={'S'} className={`${s['stat-source-descr']} ${className}`}>
      <span className={s['stat-source-descr-text']}>
        <>Статистика от</>
      </span>

      <span className={s['stat-source-descr-logo']}>
        <IconClickH />
      </span>
    </Text>
  )
}

export default StatisticFromSource
