import s from './QuickBookingSelectors.module.sass'

import React, { FC, ReactNode, useEffect, useState } from 'react'

import { useAppDispatch } from '@app/model/store'

import { getField } from '@widgets/Restaurant/QuickBooking/components/QuickBookingSelectors/helpers/getField'
import { openBooking } from '@widgets/Restaurant/Booking'

import { useField } from '@shared/lib/fields/hooks/useField/useField'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { BuildSelect } from '@shared/ui/Form/Build/BuildSelect'

import {
  fetchDayRemarkedSlots,
  getNearestRemarkedSlots,
  getNearestScheduleSlots,
  getScheduleDaySlots,
} from '@shared/lib/helpers/schedule/schedule'

import { TypeDateTimeSlot, TypeExtendedScheduleDay } from '@shared/types/schedule/types'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { generateDatesOptions } from '@shared/lib/helpers/schedule/schedule'

import IconArrow from '@icons/icon-arrow-right-white.svg'

interface Props {
  restData: TypeMappingRestOutput
  classNameWrapper?: string
  classNameSelector?: string
  buttonElement?: ReactNode
  onSelectGuest?: (data: TypeMappingRestOutput, select: string) => void
  onSelectDate?: (data: TypeMappingRestOutput, select: string) => void
  onSelectTime?: (data: TypeMappingRestOutput, select: string) => void
  onOpenBooking?: (data: TypeMappingRestOutput) => void
}

const QuickBookingSelectors: FC<Props> = ({
  restData,
  buttonElement,
  classNameWrapper = '',
  classNameSelector = '',
  onSelectGuest,
  onSelectDate,
  onSelectTime,
  onOpenBooking,
}) => {
  const [fieldGuests, setFieldGuests] = useField(getField('guest_count'))
  const [fieldDate, setFieldDate] = useField(getField('date'))
  const [fieldTime, setFieldTime] = useField(getField('time'))
  const [loadingDate, setLoadingDate] = useState(true)
  const [loadingTime, setLoadingTime] = useState(true)
  const dispatch = useAppDispatch()
  const hasRemarked = restData.booking.afisha.remarked.active
  const isDisabledButton = loadingDate || loadingTime || !fieldTime.value

  // Получение текста для option FieldDate (возвращает строки "сегодня", "завтра" или дату)
  const getDateValueString = (data: TypeExtendedScheduleDay) => {
    const currentDate = new Date()
    const propsDate = new Date(data.value)
    currentDate.setHours(0, 0, 0, 0)
    propsDate.setHours(0, 0, 0, 0)
    const isToday = currentDate.getTime() === propsDate.getTime()

    currentDate.setHours(24, 0, 0, 0)
    const isTomorrow = currentDate.getTime() === propsDate.getTime()

    if (isToday) return 'Сегодня'
    else if (isTomorrow) return 'Завтра'
    else return data.itemTextShort
  }

  useEffect(() => {
    const datesOptions = generateDatesOptions(restData.schedule.week, { countDays: 21 })
    const daysOpened = datesOptions.filter((el) => el.isRestOpened)
    const datesOptionsFormatted = generateDateOptions(datesOptions)

    if (hasRemarked) {
      // @ts-ignore
      updateSelectorsRemarked({ day: null, guests: fieldGuests.value.value })
    } else {
      const slots = getNearestScheduleSlots(daysOpened)
      const slotsOptionsFormatted = generateSlotsOptions(slots)
      // Устанавливаем ближайшую дату
      // Она должна иметь как минимум два доступных слота времени.
      // Если слотов меньше двух, то ставится следующий день
      // заполняем options для селектора выборы даты
      if (slots?.length) {
        // @ts-ignore
        setFieldDate((prev) => ({
          ...prev,
          value: datesOptionsFormatted.find((day) => day.value === slots[0].day.value),
          settings: { ...prev.settings, options: datesOptionsFormatted },
        }))

        // @ts-ignore
        setFieldTime((prev) => ({
          ...prev,
          value: slotsOptionsFormatted[0],
          settings: { ...prev.settings, options: slotsOptionsFormatted },
        }))
      }

      setLoadingDate(false)
      setLoadingTime(false)
    }
  }, [restData])

  const updateSelectorsRemarked = ({ day = null, guests = 2 }: { day?: TypeExtendedScheduleDay; guests: number }) => {
    // Если день не передан, то функция подразумевает что мы хотим отобразить ближайшую дату со свободными слотами
    if (day) {
      const optionsRemarkedRequest = { date: day, restSlug: restData.url.slug, guests }
      setLoadingTime(true)
      fetchDayRemarkedSlots(optionsRemarkedRequest).then((slots) => {
        if (slots?.length) {
          const slotsOptionsFormatted = generateSlotsOptions(slots)

          // @ts-ignore
          setFieldTime((prev) => ({
            ...prev,
            value: slotsOptionsFormatted[0],
            settings: { ...prev.settings, options: slotsOptionsFormatted },
          }))
        } else {
          // @ts-ignore
          setFieldTime((prev) => ({
            ...prev,
            value: null,
            settings: { ...prev.settings, options: [], placeholder: 'Нет мест' },
          }))
        }
        setLoadingTime(false)
      })
    } else {
      const datesOptions = generateDatesOptions(restData.schedule.week, { countDays: 21 })
      const daysOpened = datesOptions.filter((el) => el.isRestOpened)
      const datesOptionsFormatted = generateDateOptions(datesOptions)

      const optionsRemarkedRequest = {
        dates: daysOpened,
        restSlug: restData.url.slug,
        guests: guests,
        maxNearestDaysRequests: 3,
      }
      setLoadingTime(true)
      setLoadingDate(true)
      getNearestRemarkedSlots(optionsRemarkedRequest).then((slots) => {
        const slotsOptionsFormatted = generateSlotsOptions(slots)

        // @ts-ignore
        setFieldDate((prev) => ({
          ...prev,
          value: datesOptionsFormatted.find((day) => day.value === slots[0].day.value),
          settings: { ...prev.settings, options: datesOptionsFormatted },
        }))

        // @ts-ignore
        setFieldTime((prev) => ({
          ...prev,
          value: slotsOptionsFormatted[0],
          settings: { ...prev.settings, options: slotsOptionsFormatted },
        }))

        setLoadingTime(false)
        setLoadingDate(false)
      })
    }
  }

  /**
   * При изменении количества гостей мы должны пройти данные этапы:
   * 1. Проверка есть ли ремаркед
   * 2. Если ремаркед есть - делать запросы на дни, пока не найдется хоть один подходящий слот
   * 3. Если ремаркеда нет - подставить слоты на выбранный пользователем день
   * */
  const handleChangeGuests = (value) => {
    if (hasRemarked) {
      // @ts-ignore
      updateSelectorsRemarked({ day: fieldDate.value.source as TypeExtendedScheduleDay, guests: value.value })
    }
    onSelectGuest && onSelectGuest(restData, value.label)
  }
  const handleChangeDate = (value) => {
    if (hasRemarked) {
      // @ts-ignore
      updateSelectorsRemarked({ day: value.source, guests: fieldGuests.value.value })
    } else {
      // Генерируем слоты времени на основе выбранного дня
      const slots = getScheduleDaySlots(value.source as TypeExtendedScheduleDay)

      // Генерируем массив options для селектора времени
      const slotsOptionsFormatted = generateSlotsOptions(slots)

      // Подставляем значения
      // Выбранным значением селектора становится ближайший по времени слот для выбранной даты
      // @ts-ignore
      setFieldTime((prev) => ({
        ...prev,
        value: slotsOptionsFormatted[0],
        settings: { ...prev.settings, options: slotsOptionsFormatted },
      }))
    }

    onSelectDate && onSelectDate(restData, value.value)
  }

  // Генерация массива options для селектора времени
  const generateDateOptions = (dates: TypeExtendedScheduleDay[]) => {
    return dates.map((day) => ({
      source: day,
      value: day.value,
      label: getDateValueString(day),
      disabled: !day.isRestOpened,
    }))
  }

  // Генерация массива options для селектора времени
  const generateSlotsOptions = (slots: TypeDateTimeSlot[]) => {
    return slots.map((slot) => ({
      source: slot.time,
      value: slot.time.value,
      label: slot.time.value,
      disabled: false,
    }))
  }

  const handleChangeTime = (value) => {
    onSelectTime && onSelectTime(restData, value.value)
  }

  const handleOpenBooking = () => {
    // @ts-ignore
    const dateValue = fieldDate?.value?.source
    // @ts-ignore
    const timeValue = fieldTime?.value?.source
    // @ts-ignore
    const guestsValue = fieldGuests?.value?.value

    const valid = dateValue && timeValue && guestsValue && !isDisabledButton

    if (valid) {
      dispatch(openBooking(restData, { date: dateValue, time: timeValue, guests: guestsValue }))
      onOpenBooking && onOpenBooking(restData)
    }
  }

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${classNameWrapper}`}>
        <div className={`${s['wrapper-selector']} ${classNameSelector}`}>
          <BuildSelect
            // @ts-ignore
            field={fieldGuests}
            // @ts-ignore
            setField={setFieldGuests}
            handleChange={handleChangeGuests}
            className={s.selector}
          />
        </div>
        {/*// @ts-ignore */}
        <div className={`${s['wrapper-selector']} ${classNameSelector}`} data-loading={loadingDate}>
          <LoadingSpinner loading={loadingDate} className={s['selector-loading']} size={12} />
          <BuildSelect
            // @ts-ignore
            field={fieldDate}
            // @ts-ignore
            setField={setFieldDate}
            handleChange={handleChangeDate}
            className={s.selector}
          />
        </div>
        {/*// @ts-ignore */}
        <div className={`${s['wrapper-selector']} ${classNameSelector}`} data-loading={loadingTime}>
          <LoadingSpinner loading={loadingTime} className={s['selector-loading']} size={12} />
          <BuildSelect
            // @ts-ignore
            field={fieldTime}
            // @ts-ignore
            setField={setFieldTime}
            handleChange={handleChangeTime}
            className={s.selector}
          />
        </div>

        <button
          type={'button'}
          onClick={handleOpenBooking}
          data-disabled={isDisabledButton}
          className={s['button-wrapper']}>
          {buttonElement || (
            <IconButton
              mode={'black'}
              className={s['default-button']}
              sizes={'L'}
              icon={IconArrow}
              disabled={isDisabledButton}
            />
          )}
        </button>
      </div>
    </ErrorBoundary>
  )
}

export default QuickBookingSelectors
