import React, { FC, ReactNode, useEffect, useState } from 'react'
import s from './Dropdown.module.sass'
import { ErrorBoundary } from '../../../../../lib/components/ErrorBoundary'
import { DropdownContext } from '@shared/ui/Form/Single/Filters/Dropdown/Context'
import DropdownButton from '@shared/ui/Form/Single/Filters/Dropdown/DropdownButton'
import DropdownModal from '@shared/ui/Form/Single/Filters/Dropdown/DropdownModal'

interface Props {
  children: ReactNode
  openedPopup?: boolean
}

const Dropdown: FC<Props & React.ButtonHTMLAttributes<any>> = ({ children, openedPopup }) => {
  const [context, setContext] = useState({
    floatingRelative: null,
    opened: typeof openedPopup === 'boolean' ? openedPopup : false,
  })

  useEffect(() => {
    setContext((prev) => ({
      ...prev,
      opened: openedPopup,
    }))
  }, [openedPopup])

  return (
    <ErrorBoundary>
      {/*// @ts-ignore*/}
      <DropdownContext.Provider value={[context, setContext]}>{children}</DropdownContext.Provider>
    </ErrorBoundary>
  )
}

const DropdownNamespace = Object.assign(Dropdown, { Button: DropdownButton, Popup: DropdownModal })

export default DropdownNamespace
