import s from './OwnerPublishedOffer.module.sass'

import React from 'react'
import Image from 'next/image'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import {
  openEditMenuForm,
  openEditNewsForm,
  openEditSpecialForm,
} from '@pages/OwnerPersonalPage/model/OwnerPostsModel/reducer'

import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'

import IconEdit from '@icons/icon-edit.svg'
import { CardService } from '@shared/ui/Layout/CardService'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

/**
 * @function getLocalDate
 * @param thisDateJson - 2024-08-05T04:31:34.701Z
 * @returns 05.08.2024
 */
const getLocalDate = (thisDateJson) =>
  new Date(thisDateJson).toLocaleString('ru', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    // @ts-ignore
    timezone: 'UTC',
  })

/**
 * @function OwnerNewsElem
 */
const OwnerPublishedOffer = () => {
  const publishedPost = useAppSelector((store) => store.pages.owner_personal.posts.published)
  const dispatch = useAppDispatch()
  if (!publishedPost) return

  // @ts-ignore
  const { id, type, publicDate, modifiedDate, dishes, title, description, preview } = publishedPost

  const handleEditNews = (ev) => {
    if (type === 'news') dispatch(openEditNewsForm(publishedPost))
    else if (type === 'special') dispatch(openEditSpecialForm(publishedPost))
    // @ts-ignore
    else if (type === 'menu') dispatch(openEditMenuForm(publishedPost))
  }

  return (
    <ErrorBoundary>
      <div className={s['owner-news-item']} data-news-id={id}>
        {publicDate && (
          <Title sizes={'h5'} tag={'h3'}>
            Опубликовано {getLocalDate(publicDate)}
          </Title>
        )}

        {type === 'menu' ? (
          <div className={s.menu}>
            <Title tag={'h2'} sizes={'h2 h3 h3 h4'}>
              Новые блюда
            </Title>

            <div className={s.dishes}>
              {dishes.map((el, index) => (
                <CardService
                  key={el.id}
                  title={el.title}
                  subtitle={`${el.price} ₽`}
                  description={el.description}
                  img={el.preview}
                  className={s.dish}
                />
              ))}
            </div>

            <Button
              className={s['owner-news-item-edit-button']}
              sizes={'L'}
              mode={'white-border'}
              onClick={(ev) => handleEditNews(ev)}>
              <span className={s['owner-news-item-edit-icon']}>
                <IconEdit />
              </span>
            </Button>
          </div>
        ) : (
          <div className={s['owner-news-item-wrap-with-image']}>
            {preview && (
              <div className={s['owner-news-item-image-block']}>
                <Image src={preview} alt={'Фото'} layout='fill' />
              </div>
            )}

            <div className={s['owner-news-item-white-block']}>
              <div
                className={`${s['owner-news-item-wrap-content']} ${s['owner-news-item-wrap-content--with-w-shadow']}`}>
                <Text sizes={'S'} className={s['owner-news-item-type']}>
                  {type === 'news' && 'Новость'}
                  {type === 'special' && 'Специальное предложение'}
                </Text>

                {title && (
                  <Title sizes={'h3'} tag={'h4'} className={s['owner-news-item-title-name']}>
                    {title}
                  </Title>
                )}

                {description && (
                  <Text sizes={'M'} className={s['owner-news-item-data-text']}>
                    {description}
                  </Text>
                )}

                <Button
                  className={s['owner-news-item-edit-button']}
                  sizes={'L'}
                  mode={'white-border'}
                  onClick={(ev) => handleEditNews(ev)}>
                  <span className={s['owner-news-item-edit-icon']}>
                    <IconEdit />
                  </span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default OwnerPublishedOffer
