import s from './StatisticItem.module.sass'

import React, { FC } from 'react'

import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  title: string
  value: number
  diff: number
}

const formattedValue = (thisVal) => thisVal.split(',').join(' ')

const StatisticItem: FC<Props> = ({ title, value, diff }) => {
  return (
    <ErrorBoundary>
      <div className={s['stat-wrap']}>
        {/* лейбл */}
        <Text sizes={'M'} className={s['stat-title']}>
          {title}
        </Text>

        <span className={s['stat-value-wrap']}>
          {/* значение */}
          <Text sizes={'L'} className={s['stat-value-number']}>
            {value ? formattedValue(value.toLocaleString()) : 0}
          </Text>

          {/* проценты */}
          {!!diff && diff !== 0 && (
            <Text sizes={'M'} className={s['stat-value-percent']} data-is-up={diff > 0}>
              {diff > 0 && '+'}
              {diff}%
            </Text>
          )}
        </span>
      </div>
    </ErrorBoundary>
  )
}

export default StatisticItem
