import React, { FC } from 'react'
import s from './QuickBookingDots.module.sass'
import IconDots from '@icons/icon-dots.svg'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
export interface PropsQuickBookingDots {
  onClick: () => void
  className?: string
}
const QuickBookingDots: FC<PropsQuickBookingDots> = ({ onClick, className = '' }) => {
  return (
    <ErrorBoundary>
      <button className={`${s.dots} ${className}`} onClick={onClick}>
        <IconDots />
      </button>
    </ErrorBoundary>
  )
}

export default QuickBookingDots
