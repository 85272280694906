import { TypeMappingRestStatus } from '@shared/api/middleware/mappingAPI/restaurant/type'

export const mappingRestStatus = (data): TypeMappingRestStatus | null => {
  const names = {
    0: 'Открыт',
    1: 'Ресторан закрыт',
    2: 'Ресторан временно закрыт',
    10: 'Ресторан удален в Афише',
  }

  try {
    if (![0, 1, 2, 10].includes(data.state)) throw new Error('incorrect rest status from backend')

    const restaurantIsOpenSoon = (open) => {
      if (!open) return false
      const openDate = new Date(open)
      const currentDate = new Date(Date.now())
      const nextDayOpenDate = new Date(openDate)
      nextDayOpenDate.setDate(openDate.getDate() + 1)

      return openDate > currentDate && nextDayOpenDate > currentDate
    }

    const statusOpenDate = data.state === 0 && restaurantIsOpenSoon(data.open_date)

    if (statusOpenDate) {
      return {
        code: 3,
        name: `Открытие ${new Date(data.open_date).toLocaleDateString('Ru', {
          month: 'long',
          day: 'numeric',
        })} ${new Date(data.open_date).getFullYear()}`,
        open: false,
        temporarily: false,
        openingSoon: true,
      }
    }

    return {
      code: data.state,
      name: names[data.state],
      open: [0, 1].includes(data.state),
      temporarily: data.state === 1,
      openingSoon: false,
    }
  } catch (e) {
    console.error(e)
    return {
      code: 0,
      name: names[0],
      open: true,
      temporarily: false,
      openingSoon: false,
    }
  }
}
