/**
 * @fsd_page AppHome - главная
 */

import s from './AppHome.module.sass'
import React, { useEffect, useMemo } from 'react'
import { useAppSelector } from '@app/model/store'
import { TagsRoll } from '@pages/AppHome/ui/TagsRoll'
import { CompilationGrid } from '@widgets/News/CompilationGrid'
import { NewsCard, NewsCardSkeleton } from '@widgets/News/NewsCard'
import { RestCard, RestCardSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard'
import { PromoRoll } from '@features/PromoRoll'
import { ContainerLong } from '@shared/ui/Layout/Containers/ContainerLong'
import { ContainerLongLeft } from '@shared/ui/Layout/Containers/ContainerLongLeft'
import { ContainerTwoColumns } from '@shared/ui/Layout/Containers/ContainerTwoColumns'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import SectionTitle from '@shared/ui/Layout/SectionTitle/SectionTitle'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { NewsCardCover } from '@widgets/News/NewsCardCover'
import { SelectionCardCompilation, SelectionCardCompilationSkeleton } from '@widgets/Selection/SelectionCardCompilation'
import { EGG } from '@shared/api/analytics'
import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import { AdvertSidebarSticky } from '@shared/ui/Layout/AdvertTemplate/ui/AdvertSidebarSticky'
import { RestaurantMainCards } from '@widgets/Restaurant/RestaurantMainCards'
import { useGetABTests } from '@shared/lib/hooks/useGetABTests'
import { RestaurantHomeFilters } from 'src/2_pages/AppHome/ui/RestaurantHomeFilters'

export default function AppHome(props) {
  const { isMobile, isTablet, isDesktopLarge } = useClientResponsive()
  const stateGlobal = useAppSelector((state) => state.global)
  const stateHome = useAppSelector((state) => state.pages.app_home)
  const { currentCity } = stateGlobal
  const {
    featureRests,
    tags,
    bookingRests,
    specialRests,
    ratingRests,
    afishaSelections,
    weekPlans,
    restaurantsNews,
    otherNews,
  } = stateHome.sections

  const tagsFormatted = useMemo(() => {
    if (!tags.list?.length) return

    // @ts-ignore
    return tags.list.map((tag) => ({
      id: tag.tag_object.id,
      name: tag.tag_object.name,
      slug: tag.tag_object.slug,
    }))
  }, [tags.list])

  useEffect(() => {
    if (stateGlobal.currentCity?.id) EGG.pages.AppHome.appear_screen_page()
  }, [stateGlobal.currentCity?.id])

  const ABTests = useGetABTests()

  const ABTestBooking = ABTests.find((test) => test.name === 'AB_OPEN_BOOKING_1')

  // AB enabled for group C
  if (ABTestBooking) {
    ABTestBooking.group = 'C'
  }

  const isABTestC = ABTestBooking?.group === 'C'

  const activeMainCardsSkeleton = featureRests.loading
  const activeTagsRollSkeleton = tags.loading

  const showMainSection = Boolean(
    activeMainCardsSkeleton || activeTagsRollSkeleton || featureRests.list?.length || tags.list?.length,
  )
  const showSectionSpecialRests = Boolean(specialRests.loading || specialRests.list?.length)
  const showBookingRests = Boolean(isABTestC && !bookingRests.loading && bookingRests.list?.length)
  const showSectionNewsInteresting = Boolean(restaurantsNews.loading || restaurantsNews.list?.length)
  const showSectionRecommended = Boolean(ratingRests.loading || ratingRests.list?.length)
  const showAllNews = Boolean(otherNews.loading || otherNews.list?.length)
  const showAfishaSelected = Boolean(afishaSelections.loading || afishaSelections.list?.length)
  const showWeekPlans = Boolean(weekPlans.loading || !!weekPlans?.data)

  return (
    <ErrorBoundary>
      <main>
        {showMainSection && (
          <div className={s['main-section']}>
            <RestaurantMainCards list={featureRests.list} skeleton={activeMainCardsSkeleton} />

            <ContainerLong classNameContent={s['cover-roll-content']}>
              <TagsRoll tags={tagsFormatted} skeleton={activeTagsRollSkeleton} />
            </ContainerLong>
          </div>
        )}

        <div className={s['container-main-wrapper']}>
          {/* Маркетинговые блоки */}
          <PromoRoll />

          {!isDesktopLarge && (
            <ContainerLongLeft>
              <RestaurantHomeFilters />
            </ContainerLongLeft>
          )}

          <ContainerTwoColumns>
            <ContainerTwoColumns.Main className={s['container-main']}>
              {isDesktopLarge && <RestaurantHomeFilters />}

              {showBookingRests && (
                <SectionCarousel
                  countSlidesOnContainer={3}
                  title={'Бронируйте прямо сейчас'}
                  link={`/${currentCity?.slug}/restaurants/restorany-kotorye-nado-bronirovat-pryamo-sejchas/`}
                  className={s['booking-now-rests-slider-roll']}
                  skeleton={bookingRests.loading}>
                  <SectionCarousel.Items>
                    {bookingRests.list?.map((item, index) => (
                      <RestCard
                        key={item.id}
                        data={item}
                        sizes={'L ML'}
                        type={'booking'}
                        analytic={{
                          advertCardPlaceType: null,
                          section_name: `Бронируйте прямо сейчас`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(4).keys()).map((i, idx) => (
                      <RestCardSkeleton key={idx} sizes={'L ML'} />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              )}

              {/* Спецпредложения, (рестораны) */}

              {showSectionSpecialRests && (
                <SectionCarousel
                  countSlidesOnContainer={3}
                  title={'Спецпредложения'}
                  link={`/${currentCity?.slug}/restaurants/restaurant_list/owner_offer/`}
                  className={s['special-rests-slider-roll']}
                  skeleton={specialRests.loading}>
                  <SectionCarousel.Items>
                    {specialRests.list?.map((item, index) => (
                      <RestCard
                        key={item.id}
                        data={item}
                        sizes={'L ML'}
                        type={'special-offer'}
                        analytic={{
                          advertCardPlaceType: null,
                          section_name: `Спецпредложения`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(4).keys()).map((i, idx) => (
                      <RestCardSkeleton key={idx} sizes={'L ML'} />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              )}

              {/* Самое интересное про рестораны, (новости) */}
              {showSectionNewsInteresting && (
                <SectionCarousel
                  countSlidesOnContainer={2}
                  title={'Самое интересное про рестораны'}
                  link={`/${currentCity?.slug}/restaurant-news/`}
                  skeleton={restaurantsNews.loading}>
                  <SectionCarousel.Items>
                    {restaurantsNews.list?.map((news, index) => (
                      <NewsCard
                        sizes={(index + 1) % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'}
                        key={news.id}
                        data={news}
                        analytic={{
                          section_name: `Самое интересное про рестораны`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(4).keys()).map((i, idx) => (
                      <NewsCardSkeleton
                        key={idx}
                        sizes={(idx + 1) % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'}
                      />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              )}

              {/* Рекомендации для вас, (рестораны) */}
              {showSectionRecommended && (
                <SectionCarousel
                  countSlidesOnContainer={3}
                  title={'Рекомендации для вас'}
                  className={s['by-rating-rests-slider-roll']}
                  skeleton={ratingRests.loading}>
                  <SectionCarousel.Items>
                    {ratingRests.list?.map((item, index) => (
                      <RestCard
                        key={item.id}
                        data={item}
                        sizes={'L ML'}
                        type={'list'}
                        analytic={{
                          advertCardPlaceType: null,
                          section_name: `Рекомендации`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(4).keys()).map((i, idx) => (
                      <RestCardSkeleton key={idx} sizes={'L ML'} />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              )}

              <AdvertTemplate type={'100%x240 Listing1'} />

              {/* Статьи, новости, обзоры (новости) */}
              {showAllNews && (
                <SectionCarousel
                  countSlidesOnContainer={3}
                  title={'Статьи, новости, обзоры'}
                  link={`/${currentCity?.slug}/restaurant-news/`}
                  skeleton={otherNews.loading}>
                  <SectionCarousel.Items>
                    {otherNews.list?.map((news, index) => (
                      <NewsCard
                        sizes={index % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'}
                        data={news}
                        key={news.id}
                        analytic={{
                          section_name: `Статьи, новости, обзоры`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(4).keys()).map((i, idx) => (
                      <NewsCardSkeleton key={idx} sizes={idx % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'} />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              )}
            </ContainerTwoColumns.Main>

            {!isMobile && !isTablet && (
              <ContainerTwoColumns.Aside>
                <AdvertTemplate type={'240x400'} container={AdvertSidebarSticky} />
                <AdvertTemplate type={'240x400_2nd'} container={AdvertSidebarSticky} />
              </ContainerTwoColumns.Aside>
            )}
          </ContainerTwoColumns>

          {/* Планы на неделю */}
          {showWeekPlans && (
            <ContainerLongLeft>
              <NewsCardCover
                data={weekPlans.data}
                subtitles={['5 минут на чтение']}
                analytic={{
                  section_index: 0,
                  section_name: `Планы на неделю`,
                }}
                skeleton={weekPlans.loading}
              />
            </ContainerLongLeft>
          )}

          <AdvertTemplate type={'Parallax Footer'} container={ContainerLongLeft} />

          {/* Выбор Афиши */}
          {showAfishaSelected && (
            <ContainerLongLeft>
              {isMobile ? (
                <SectionCarousel
                  title={'Выбор Афиши'}
                  link={`/${currentCity?.slug}/restaurants/afisha_selected/`}
                  countSlidesOnContainer={3}
                  skeleton={afishaSelections.loading}>
                  <SectionCarousel.Items>
                    {afishaSelections.list?.map((item, index) => (
                      <SelectionCardCompilation
                        key={item.id}
                        data={item}
                        sizes={'M1x1'}
                        analytic={{
                          section_name: `Выбор Афиши`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(6).keys()).map((i, idx) => (
                      <SelectionCardCompilationSkeleton key={idx} sizes={'M1x1'} />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              ) : (
                <section className={s['home-selections-grid']}>
                  <SectionTitle
                    title={'Выбор Афиши'}
                    link={`/${currentCity?.slug}/restaurants/afisha_selected/`}
                    skeleton={afishaSelections.loading}
                  />
                  <CompilationGrid
                    type={'selections'}
                    counts={'6 6 4'}
                    list={afishaSelections.list}
                    analytic={{ section_name: `Выбор Афиши` }}
                    skeleton={afishaSelections.loading}
                  />
                </section>
              )}
            </ContainerLongLeft>
          )}

          {!isMobile && <AdvertTemplate type={'Context_full'} container={ContainerLongLeft} />}
        </div>
      </main>
    </ErrorBoundary>
  )
}
