/**
 * @todo merge file with /.authorization.ts
 */

declare const window: Window &
  typeof globalThis & {
    screenVersion: any
    ramblerIdHelper: any
  }

export interface Unsubscribe {
  (): void
}

export const getByIndex = <TValue, TDefault = null>(
  array: TValue[] | null | undefined,
  index: number,
  default_: TDefault = null as unknown as TDefault,
): TValue | TDefault => {
  if (!array || !array.length) {
    return default_
  }

  return index >= 0
    ? index < array.length
      ? array[index]
      : default_
    : array.length + index < array.length && array.length + index >= 0
    ? array[array.length + index]
    : default_
}

getByIndex.first = <TValue, TDefault = null>(array: TValue[], default_: TDefault = null as unknown as TDefault) =>
  getByIndex(array, 0, default_)

getByIndex.last = <TValue, TDefault = null>(array: TValue[], default_: TDefault = null as unknown as TDefault) =>
  getByIndex(array, array.length - 1, default_)

// eslint-disable-next-line
declare namespace GetProfileInfo {
  interface Avatar {
    found: number
    provider: string
    provider_icon: string
    url: string
  }

  interface Display {
    avatar: Avatar
    display_name: string
    short_name: string
  }

  interface Accounts {
    email: string[]
    phone: any[]
  }

  interface ChainId {
    all: string[]
    default: string
  }

  // eslint-disable-next-line
  interface Default {}

  interface Championat {
    all: any[]
    default: Default
  }

  interface Contact {
    confirmation_ip: string
    confirmation_timestamp: number
    contact: string
    creation_ip: string
    creation_timestamp: number
    type: 'EMAIL' | 'PHONE'
  }

  interface Emails {
    confirmed: string[]
    unconfirmed: any[]
  }

  interface Account {
    account_id: string
    account_type: string
    creation_time: number
    password_type: string
    provider: string
  }

  interface RawAccounts {
    accounts: Account[]
    default_accounts: any[]
  }

  interface Utm {
    referer: string
  }

  interface Via {
    project: string
    type: string
  }

  interface Credentials {
    account: string
    ip: string
    subtype: string
    time: number
    type: string
    user_agent: string
    utm: Utm
    via: Via
  }

  interface Utm2 {
    referer: string
  }

  interface Via2 {
    project: string
    type: string
  }

  interface Session {
    credentials: Credentials
    deletion_key: string
    utm: Utm2
    valid_for: number
    via: Via2
  }

  // eslint-disable-next-line
  interface Regheaders {}

  interface Vrtverdict {
    duplicate: number
    found: number
    hasVarity: number
    ipOk: number
    isVaritiUp: number
    refererOk: number
    verdict?: any
  }

  interface Utm3 {
    referer: string
  }

  interface Via3 {
    project: string
    type: string
  }

  interface Properties {
    __recaptchav3score: string
    __regheaders: Regheaders
    __vrtverdict: Vrtverdict
    birthday: string
    firstname: string
    gender: string
    geoid: number
    id: string
    ip_address: string
    lastname: string
    middlename: string
    oauth_email: string
    phone: string
    user_agent: string
    utm: Utm3
    via: Via3
  }

  interface SocialProfile {
    ext_uid: string
    properties: Properties
    provider: string
  }

  interface Utm4 {
    referer: string
  }

  interface Via4 {
    project: string
    type: string
  }

  interface Info {
    // accounts: Accounts;
    chain_id?: ChainId
    // championat: Championat;
    cinema_mtime: number
    // contacts?: Contact[];
    ctime: number
    emails?: Emails
    phones?: string[]
    // rambler_mail_phone_timestamp: number;
    // raw_accounts: RawAccounts;
    // session: Session;
    social_profiles: SocialProfile[]
    utm: Utm4
    via: Via4
  }

  interface Profile {
    firstname: string
    lastname: string
  }

  export interface Response {
    __type: string
    display: Display
    info: Info
    profile: Profile
  }
}

type Provider =
  | 'sberbank'
  | 'sberbank_v2'
  | 'apple'
  | 'vkontakte'
  | 'livejournal'
  | 'odnoklassniki'
  | 'mailru'
  | 'google'
  | 'pgumosru'

type ProfileInfoOptions = 'contacts' | 'session_info' | 'championat' | 'accounts' | 'chain_id' | 'social_profiles'

interface RedirectToAuthOptions {
  backUrl?: string
}

class _RamblerIDAPI {
  static logout = (callback?: (data: null | Error) => void) => {
    window.ramblerIdHelper?.logout(callback ? (...args: any) => callback(args[1]) : undefined)
  }

  static closeAuth = () => {
    window.ramblerIdHelper?.closeAuth()
  }

  static openAuth = (options: Record<string, unknown>) => {
    window.ramblerIdHelper?.openAuth(options)
  }

  static onFrameRedirect = (callback: () => void): Unsubscribe => {
    window.ramblerIdHelper?.addListener('redirect', callback)
    return () => window.ramblerIdHelper?.removeListener('redirect', callback)
  }

  static onFrameClose = (callback: () => void): Unsubscribe => {
    window.ramblerIdHelper?.addListener('close', callback)
    return () => window.ramblerIdHelper?.removeListener('close', callback)
  }

  static onLogin = (callback: () => void): Unsubscribe => {
    window.ramblerIdHelper?.addListener('login', callback)
    return () => window.ramblerIdHelper?.removeListener('login', callback)
  }

  static onOauthLogin = (callback: () => void): Unsubscribe => {
    window.ramblerIdHelper?.addListener('oauthlogin', callback)

    return () => window.ramblerIdHelper?.removeListener('oauthlogin', callback)
  }

  static onLogout = (callback: () => void): Unsubscribe => {
    window.ramblerIdHelper?.addListener('logout', callback)
    return () => window.ramblerIdHelper?.removeListener('logout', callback)
  }

  static onReady = async (callback: () => void) => {
    // eslint-disable-next-line
    ;(window.ramblerIdHelper = window.ramblerIdHelper || []).push(callback)
  }

  static getSessionToken = (callback: (data: { ctime: number; token: string; ttl: number } | null) => void) =>
    window.ramblerIdHelper?.getSessionToken(callback)

  static _getProfileInfo = (
    callback: (data: GetProfileInfo.Response | null) => void,
    options: ProfileInfoOptions[] = ['chain_id', 'social_profiles'],
  ) => {
    // console.log('!!! _getProfileInfo callback', callback)
    // console.log('!!! _getProfileInfo options', options)
    return window.ramblerIdHelper?.getProfileInfo(
      {
        get_contacts: options.includes('contacts'),
        get_session_info: options.includes('session_info'),
        get_championat: options.includes('championat'),
        get_accounts: options.includes('accounts'),
        get_chain_id: options.includes('chain_id'),
        get_social_profiles: options.includes('social_profiles'),
      },
      callback,
    )
  }

  static getProfileInfo = (options?: ProfileInfoOptions[]) => {
    // console.log('!!! getProfileInfo options', options)
    return new Promise<GetProfileInfo.Response>((resolve, reject) => {
      _RamblerIDAPI._getProfileInfo((data) => {
        // console.log('!!! getProfileInfo data', data)
        if (data) {
          resolve(data)
        } else {
          reject()
        }
      }, options)
    })
  }

  static getChainID = () =>
    _RamblerIDAPI.getProfileInfo(['chain_id']).then(({ info: { chain_id } }) => (chain_id ? chain_id.default : null))

  static getPhone = () =>
    _RamblerIDAPI.getProfileInfo(['contacts']).then(({ info: { phones } }) => getByIndex.first(phones ?? []))

  static isRamblerIDLinkedWith = async (...providers: Provider[]) => {
    const { info } = await _RamblerIDAPI.getProfileInfo()

    const providers_ = info.social_profiles.map(({ provider }) => provider)

    return providers.every((provider) => providers_.includes(provider))
  }

  static redirectToAuth = ({
    backUrl = location.origin + location.pathname + location.search + location.hash,
  }: RedirectToAuthOptions = {}) => {
    const theme = window.screenVersion === 'mobile' ? 'afisha-mobile' : 'afisha'

    window.ramblerIdHelper?.redirectToAuth({
      path: '/login-20/phone-login',
      theme,
      texts: theme,
      rname: 'afisha',
      back: backUrl,
    })
  }

  static redirectToProviderOAuth = (options: { action: 'login' | 'attach'; provider: Provider; source: string }) =>
    window.ramblerIdHelper?.redirectToProviderOAuth({
      back: location.origin + location.pathname + location.search,
      ...options,
    })
}

// export const RamblerIDAPI = new ExternalAPI('RamblerIDAPI', 'https://id.rambler.ru/rambler-id-helper/auth_events.js')
//   .setResult(() => _RamblerIDAPI)
//   .setReadyValidator(async ({ ok, timeout }) => {
//     timeout(Time.SECOND * 10)
//     _RamblerIDAPI.onReady(ok)
//   })

export const RamblerIDAPI = _RamblerIDAPI
